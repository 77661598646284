<template>
  <div v-if="(entity !== null) && hasPerm('youth.view_seanceinscription')">
    <div>
      <div class="field-group-block seances-field-group-block">
        <div class="field-group-header">
          <b-row>
            <b-col cols="4">
              <b>Séances {{ youthHomeLabel }}</b>
            </b-col>
            <b-col cols="5">
              <b-select
                id="onlyForthcoming"
                v-model="onlyForthcoming"
                name="onlyForthcoming"
                class="small-select"
              >
                <b-select-option value="0" v-if="hasFullYear">Périodes et années complètes</b-select-option>
                <b-select-option value="0" v-if="!hasFullYear">Toutes les périodes</b-select-option>
                <b-select-option value="1">Périodes à venir</b-select-option>
                <b-select-option value="2">Périodes passées</b-select-option>
                <b-select-option value="3" v-if="hasFullYear">Années complètes</b-select-option>
                <b-select-option value="4" v-if="hasFullYear">Toutes les périodes</b-select-option>
              </b-select>
              <b-form-checkbox
                id="includeCancelledActivities"
                v-model="includeCancelledActivities"
                name="includeCancelledActivities"
                :value="true"
                :unchecked-value="false"
                :disabled="disableIncludeCancelledActivities"
              >
                Affiche les inscriptions {{youthHomeLabel}} annulées
              </b-form-checkbox>
            </b-col>
            <b-col cols="3" class="text-right" v-if="canChange">
              <a class="btn btn-sm btn-secondary btn-list"
                 href
                 v-if="activeTab.name !== 'existing'"
                 @click.prevent="onList"
              >
                <i class="fa fa-list"></i> Liste
              </a>
              <a class="btn btn-sm btn-primary btn-next"
                 href
                 v-if="activeTab.name === 'seances'"
                 :class="hasInscriptionsToBeConfirmed ? '' : 'disabled'"
                 @click.prevent="onNext"
              >
                <i class="fa fa-calendar-times"></i> Inscrire
              </a>
              <a class="btn btn-sm btn-secondary btn-prev"
                 href
                 v-if="activeTab.name !== 'seances'"
                 @click.prevent="onPrev"
              >
                <i class="fas fa-calendar-alt"></i> Retour à la grille
              </a>
            </b-col>
          </b-row>
        </div>
        <div v-if="!family" class="message-warning">
          <i class="fa fa-exclamation-circle"></i>
          Attention cette fiche ne correspond pas à une famille
        </div>
        <div v-if="hasNoFamilyLevel" class="message-warning">
          <i class="fa fa-exclamation-circle"></i>
          Attention le quotient familial de cette famille n'est pas renseigné
        </div>
        <div>
          <seances-filter
            :filters="getSynthesisFilters()"
            :week-days="weekDaysFilters"
            :day-times="dayTimesFilters"
            :date-from="dateFromFilter"
            :date-to="dateToFilter"
            @filterChanged="filterChanged"
          >
          </seances-filter>
          <div>
            <seances-filters-indicator
              :week-days="weekDaysFilters"
              :day-times="dayTimesFilters"
              :date-from="dateFromFilter|dateToString"
              :date-to="dateToFilter|dateToString"
              v-if="activeTab.name === 'seances'"
            >
            </seances-filters-indicator>
            <div class="field-line field-line-no-border">
              <tabs-bar
                :tabs="tabs"
                :active="activeTab"
                :enabled="!editDisabled"
                secondary
                @change="tabChanged($event)"
              ></tabs-bar>
            </div>
            <div v-show="activeTab.name === 'seances'">
              <loading-gif :loading-name="synthesisLoading"></loading-gif>
              <div class="field-line">
                <b>Grille d'inscriptions</b>
              </div>
              <div v-if="synthesisElements.length === 0 && !isLoading(synthesisLoading)" class="field-line">
                Aucune séance
              </div>
              <div
                class="field-line"
                v-if="synthesisElements.length > 0 && !isLoading(synthesisLoading) && canChange"
              >
                <div v-if="showAddDiscount">
                  <a href @click.prevent="showAddDiscount = false">Cacher les réductions</a>
                  <add-youth-discount
                    :entity="entity"
                    :individuals="theIndividuals"
                    :only-individuals="onlyIndividuals"
                    @discounts-changed="onDiscountsChanged">
                  </add-youth-discount>
                </div>
                <div v-else>
                  <a href @click.prevent="showAddDiscount=true">Voir les réductions</a>
                </div>
              </div>
              <div
                class="field-line"
                v-if="synthesisElements.length > 0 && !isLoading(synthesisLoading) && theIndividuals.length > 1"
              >
                <b>Voir seulement les inscriptions de</b>
                <entity-children-selector
                  :individuals="theIndividuals"
                  @changed="onlyIndividuals = $event.choices"
                >
                </entity-children-selector>
              </div>
              <div v-if="filteredSynthesisElements(false).length === 0" class="empty-value field-line">
                Aucune période
              </div>
              <div v-if="!isLoading(synthesisLoading)" class="field-line">
                <div
                  v-for="elt in synthesisElements"
                  :key="elt.index"
                  class="field-line"
                  v-show="isSynthesisElementVisible(elt, false)"
                >
                  <entity-seances-list
                    :entity="entity"
                    :elt="elt"
                    :seances-filter="synthesisFilter"
                    :include-cancelled-activities="includeCancelledActivities"
                    :only-individuals="onlyIndividuals"
                    @seances-loaded="onSeancesLoaded"
                    @inscription-changed="onInscriptionChanged"
                    @limits-loaded="onLimitsLoaded"
                  >
                  </entity-seances-list>
                </div>
              </div>
              <div>
                <b-row class="buttons-bar">
                  <b-col class="text-right" v-if="canChange">
                    <a class="btn btn-primary btn-next"
                       href
                       :class="hasInscriptionsToBeConfirmed ? '' : 'disabled'"
                       @click.prevent="onNext"
                    >
                      <i class="fa fa-calendar-times"></i> Inscrire
                    </a>
                  </b-col>
                </b-row>
              </div>
            </div>
            <div v-show="activeTab.name === 'inscriptions'">
              <div class="field-line">
                <b>Inscriptions à confirmer</b>
              </div>
              <loading-gif :loading-name="seancesCreationLoading"></loading-gif>
              <div v-if="!hasInscriptionsToBeConfirmed && !isLoading(seancesCreationLoading)" class="field-line">
                Aucune inscription à confirmer pour l'instant
              </div>
              <div v-if="cancellations.length > 0" class="field-line">
                <div class="header-line ut-cancellations">
                  <b>Annulations: {{ getSelectedCancellationsCount() }}</b>
                </div>
                <div
                  v-for="inscription in cancellations"
                   :key="inscription.getKey()"
                   class="field-line"
                >
                  <b-row class="ut-cancellation">
                    <b-col cols="6">
                      <b-form-checkbox
                        :id="'selectCancellation' + inscription.getKey()"
                        :checked="isCancellationSelected(inscription)"
                        :name="'selectCancellation' + inscription.getKey()"
                        :value="true"
                        :unchecked-value="false"
                        class="ut-select-cancellation"
                        @change="selectCancellation(inscription, $event)"
                      >
                        {{ inscription.seance.name }}
                      </b-form-checkbox>
                      <div>
                        <workshop-select
                          initial-value
                          :seance="inscription.seance"
                          :individual="inscription.individual"
                        ></workshop-select>
                      </div>
                    </b-col>
                    <b-col cols="3" class="text-right">{{ inscription.individual.firstAndLastName() }}</b-col>
                    <b-col cols="3" class="text-right">
                      <loading-gif :loading-name="calculatePricesLoadingName" :short="true"></loading-gif>
                      <div v-if="!isLoading(calculatePricesLoadingName)">
                        <span v-if="getCancelPrice(inscription)">
                          <span v-if="isInvoiced(inscription)">
                            <b-form-checkbox
                              :id="'refundCancellations' + inscription.getKey()"
                              :checked="isCancellationRefund(inscription)"
                              :name="'refundCancellations' + inscription.getKey()"
                              :value="true"
                              :unchecked-value="false"
                              class="ut-refund"
                              @change="changeCancellationRefund(inscription, $event)"
                            >
                              remboursement: {{ getCancelPrice(inscription) | currency }}
                            </b-form-checkbox>
                          </span>
                          <span v-else>
                            <b-form-checkbox
                              :id="'refundCancellations' + inscription.getKey()"
                              :checked="isCancellationCharged(inscription)"
                              :name="'refundCancellations' + inscription.getKey()"
                              :value="true"
                              :unchecked-value="false"
                              class="ut-invoice"
                              @change="changeCancellationCharged(inscription, $event)"
                            >
                              facturable: {{ getCancelPrice(inscription) | currency }}
                            </b-form-checkbox>
                          </span>
                        </span>
                        <span v-else>{{ 0 | currency }}</span>
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </div>
              <div v-if="newInscriptions.length > 0" class="field-line">
                <div class="header-line ut-inscriptions">
                  <b>Nouvelles inscriptions: {{ getSelectedInscriptionsCount() }}</b>
                </div>
                <div v-if="hasLimits" class="limit-indicator-info">
                  Les limites n'incluent pas les inscriptions ci-dessous
                </div>
                <div
                  v-for="inscription in newInscriptions"
                   :key="inscription.getKey()"
                   class="field-line"
                >
                  <b-row>
                    <b-col cols="6">
                      <div>
                        <b-form-checkbox
                          :id="'selectInscription' + inscription.getKey()"
                          :checked="isInscriptionSelected(inscription)"
                          :name="'selectInscription' + inscription.getKey()"
                          :value="true"
                          :unchecked-value="false"
                          :disabled="inscription.seance.fixedFee"
                          @change="selectInscription(inscription, $event)"
                        >
                          {{ inscription.seance.name }}
                          <span v-if="getMultiplierDelta(inscription) > 0">
                            <span v-if="getInitialMultiplier(inscription) > 1" class="multiplier">
                                X <counter-label :counter="getMultiplierDelta(inscription)" label="supplémentaire">
                                </counter-label>
                            </span>
                            <span v-else-if="getMultiplierDelta(inscription) > 1" class="multiplier">
                                X <counter-label :counter="getMultiplierDelta(inscription)" label="inscription">
                                </counter-label>
                            </span>
                          </span>
                          <span class="multiplier" v-else>
                              X <counter-label :counter="-1 * getMultiplierDelta(inscription)" label="annulation">
                              </counter-label>
                          </span>
                        </b-form-checkbox>
                        <div v-if="inscription.seance.youthHome.allowWaitingList" class="small-cb">
                          <b-form-checkbox
                            :id="'waitingInscription' + inscription.getKey()"
                            :checked="isInscriptionWaitingList(inscription)"
                            :name="'waitingInscription' + inscription.getKey()"
                            :value="true"
                            :unchecked-value="false"
                            :disabled="inscription.seance.fixedFee"
                            size="sm"
                            @change="setInscriptionOnWaitingList(inscription, $event)"
                          >
                            Mettre sur liste d'attente
                          </b-form-checkbox>
                        </div>
                        <div>
                          <div v-if="getLimits(inscription)">
                            <seance-limits-display
                              :day-limits="getLimits(inscription).dayLimits"
                              :group-limits="getLimits(inscription).groupLimits"
                              :seance-limits="getLimits(inscription).seanceLimits"
                              :seance-group-limits="getLimits(inscription).seanceGroupLimits"
                              :excursion-limits="getLimits(inscription).excursionLimits"
                              :excursion-group-limits="getLimits(inscription).excursionGroupLimits"
                              :age-group="getLimits(inscription).ageGroup"
                            ></seance-limits-display>
                          </div>
                          <workshop-select
                            :seance="inscription.seance"
                            :individual="inscription.individual"
                          ></workshop-select>
                        </div>
                      </div>
                      <div>
                        <span class="badge badge-light">{{ inscription.seance.youthHome.name }}</span>&nbsp;
                        <span class="badge badge-light">{{ inscription.seance.period.name }}</span>
                      </div>
                    </b-col>
                    <b-col cols="3">{{ inscription.individual.firstAndLastName() }}</b-col>
                    <b-col cols="3" class="text-right">
                      <loading-gif :loading-name="calculatePricesLoadingName" :short="true"></loading-gif>
                      <div v-if="!isCalculatePricesLoading">
                        <span v-if="priceError">
                          <i class="fa fa-exclamation-circle" v-b-tooltip="priceError"></i>
                        </span>
                        <span v-else>
                          <div v-if="getPriceMessage(inscription)" class="badge badge-light">
                            {{ getPriceMessage(inscription) }}
                          </div>
                          {{ getFinalPrice(inscription) | currency }}
                          <div>
                            <div
                              class="badge badge-light badge-block"
                              v-for="welfare of getWelfareSummary(inscription)"
                              :key="welfare.id"
                            >
                              {{ welfare.name }}: {{ welfare.getWelfareAmount() }}
                            </div>
                            <div
                              class="badge badge-light ut-discount"
                              v-for="discount of getDiscount(inscription)"
                              :key="discount.id"
                              :title="getPriceTitle(inscription)"
                            >
                              <span v-if="discount.showPercentage">
                                <span v-if="discount.percentage > 0">
                                  Réduction {{ discount.percentage }}%
                                </span>
                                <span v-else>
                                  Supplément {{ -discount.percentage }}%
                                </span>
                              </span>
                              <span v-else>
                                <span v-if="discount.amount > 0">
                                  Réduction {{ discount.amount | currency }}
                                </span>
                                <span v-else>
                                  Supplément {{ -discount.amount | currency }}
                                </span>
                              </span>
                            </div>
                          </div>
                        </span>
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </div>
              <b-row class="buttons-bar" v-if="hasInscriptionsToBeConfirmed">
                <b-col class="text-right">
                  <a class="btn btn-danger btn-save" href @click.prevent="onSave(true)">
                    Confirmer et Facturer
                  </a>
                  <a class="btn btn-secondary btn-cancel" href @click.prevent="onCancel">
                    Annuler la saisie
                  </a>
                  <a class="btn btn-primary btn-save" href @click.prevent="onSave(false)">
                    Confirmer les inscriptions
                  </a>
                </b-col>
              </b-row>
            </div>
            <div v-show="activeTab.name === 'existing'">
              <div class="field-line">
                <b>Liste des inscriptions effectuées</b>
              </div>
              <loading-gif :loading-name="synthesisLoading"></loading-gif>
              <div v-if="!isLoading(synthesisLoading)">
                <div
                  v-for="elt in synthesisElements"
                  :key="elt.index"
                  class="field-line"
                  v-show="isSynthesisElementVisible(elt, true)"
                >
                  <entity-inscriptions-list
                    :elt="elt"
                    :entity="entity"
                    :adhesions="adhesions"
                    :seances-filter="synthesisFilter"
                  >
                  </entity-inscriptions-list>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="bv-modal-cancel-inscriptions"
      @ok="onCancelInscriptions"
      ok-variant="danger"
      ok-title="Oui, j'annule cette saisie"
      cancel-title="Non, ne pas annuler"
    >
      <template v-slot:modal-title>
        Souhaitez-vous annuler la saisie des inscriptions en cours?
      </template>
      <div class="d-block">
        <p>
          Les inscriptions et annulations en cours de saisie seront perdues.
        </p>
      </div>
    </b-modal>
    <b-modal
      id="bv-modal-confirm-inscriptions"
      @ok="onConfirmInscriptions"
      cancel-title="Non"
      ok-title="Oui, inscrire"
    >
      <template v-slot:modal-title>
        Confirmez-vous l'envoi des inscriptions en cours?
      </template>
      <div class="d-block">
        <p>
          Les inscriptions et annulations sélectionnées seront prises en compte.
        </p>
        <p v-if="createInvoice">
          Les inscriptions seront automatiquement facturées
        </p>
      </div>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment'
import { mapMutations, mapActions } from 'vuex'
import store from '@/store'
import { currency } from '@/filters/texts'
import { BackendMixin } from '@/mixins/backend'
import SeancesFilter from '@/components/Seances/SeancesFilter'
import CounterLabel from '@/components/Controls/CounterLabel'
import TabsBar from '@/components/Controls/TabsBar'
import SeancesFiltersIndicator from '@/components/Seances/SeancesFiltersIndicator'
import LoadingGif from '@/components/Controls/LoadingGif'
import WorkshopSelect from '@/components/Seances/WorkshopSelect'
import AddYouthDiscount from '@/components/Discounts/AddYouthDiscount'
import SeanceLimitsDisplay from '@/components/SeanceLimits/SeanceLimitsDisplay.vue'
import EntitySeancesList from './EntitySeancesList'
import EntityInscriptionsList from './EntityInscriptionsList'
import EntityChildrenSelector from './EntityChildrenSelector'
import { TabItem } from '@/types/tabs'
import { makeSeancePrice } from '@/types/tariffs'
import { makeSeanceSynthesisElt, SeanceInscription, makeEntitySeance } from '@/types/youth'
import { existsIn } from '@/utils/arrays'
import { BackendApi } from '@/utils/http'
import { compareDates } from '@/utils/sorting'
import { includes } from '@/utils/strings'

const onlyForthcomingStorageKey = 'entityOnlyForthcomingSeancePeriods'
const includeCancelledActivitiesStorageKey = 'entityIncludeCancelledActivities'

export default {
  name: 'entity-youth',
  components: {
    CounterLabel,
    SeanceLimitsDisplay,
    AddYouthDiscount,
    WorkshopSelect,
    LoadingGif,
    SeancesFiltersIndicator,
    EntitySeancesList,
    EntityChildrenSelector,
    EntityInscriptionsList,
    SeancesFilter,
    TabsBar,
  },
  mixins: [BackendMixin],
  props: {
    showAll: Boolean,
    reload: {
      type: Boolean,
      defaultValue: true,
    },
    family: Object,
    adhesions: Array,
  },
  data() {
    return {
      editModeEnabled: true,
      activeTab: null,
      seances: [],
      refundCancellations: {},
      synthesisElements: [],
      synthesisFilter: null,
      weekDaysFilters: [ ...Array(7).keys() ].map(() => true),
      dayTimesFilters: [ ...Array(4).keys() ].map(() => true),
      dateFromFilter: null,
      dateToFilter: null,
      synthesisLoading: 'seances-synthesis-loading',
      seancesCreationLoading: 'seances-creation-loading',
      calculatePricesLoadingName: 'calculate-prices-loading',
      hasFilter: false,
      allPrices: {},
      allDiscounts: {},
      userDiscounts: {},
      forcePriceCalculation: true,
      selectedCancellations: {},
      selectedInscriptions: {},
      waitingListInscriptions: {},
      priceError: '',
      onlyForthcoming: +(window.localStorage.getItem(onlyForthcomingStorageKey) || 0),
      includeCancelledActivities: !!(window.localStorage.getItem(includeCancelledActivitiesStorageKey)),
      disableIncludeCancelledActivities: false,
      showAddDiscount: true,
      initDone: false,
      limitsMap: new Map(),
      hasLimits: false,
      onlyIndividuals: [],
      createInvoice: false,
    }
  },
  computed: {
    youthHomeLabel() {
      return store.getters.youthHomeLabel
    },
    dayTimeLabels() {
      return store.getters.dayTimeLabels
    },
    isCalculatePricesLoading() {
      return this.isLoading('calculatePricesLoadingName')
    },
    hasFullYear() {
      return this.synthesisElements.filter(elt => elt.period.id === 0).length > 0
    },
    newInscriptions() {
      const newInscriptions = []
      const avoidDuplicates = new Map()
      for (const seance of this.seances) {
        if (seance.hasNewInscriptions()) {
          for (const individual of this.entity.getMainIndividuals()) {
            if (seance.isIndividualInscriptionNew(individual.id)) {
              const key = '' + seance.id + ':' + individual.id
              if (!avoidDuplicates.has(key)) {
                avoidDuplicates.set(key, 1)
                newInscriptions.push(new SeanceInscription(0, seance, individual))
              }
            }
          }
        }
      }
      return newInscriptions
    },
    cancellations() {
      let cancellations = []
      for (let seance of this.seances) {
        if (seance.hasCancellations()) {
          for (let individual of this.entity.getMainIndividuals()) {
            if (seance.isIndividualInscriptionCancelled(individual.id)) {
              cancellations.push(new SeanceInscription(0, seance, individual))
            }
          }
        }
      }
      return cancellations
    },
    hasInscriptionsToBeConfirmed() {
      return ((this.cancellations.length > 0) || (this.newInscriptions.length > 0))
    },
    entity() {
      return this.family.entity
    },
    theIndividuals() {
      let allowAdults = false
      for (let elt of this.synthesisElements) {
        if (elt.youthHome.allowAdults()) {
          allowAdults = true
          break
        }
      }
      if (!allowAdults) {
        return this.entity.getChildrenIndividuals()
      } else {
        return this.entity.getMainIndividuals()
      }
    },
    hasNoFamilyLevel() {
      if (this.family) {
        return !this.family.hasFamilyLevel()
      }
      return false
    },
    editDisabled() {
      return (store.getters.editMode !== '' && store.getters.editMode !== 'seance-inscriptions')
    },
    entityIndividuals() {
      return this.entity.getMainIndividuals()
    },
    tabs() {
      const tabs = [
        new TabItem('seances', 'Séances', 'fas fa-calendar-alt')
      ]
      if (this.canChange) {
        tabs.push(
          new TabItem(
            'inscriptions', 'Inscriptions à confirmer', 'fas fa-calendar-times',
            this.hasInscriptionsToBeConfirmed ? '' : 'disabled'
          )
        )
      }
      tabs.push(
        new TabItem('existing', 'Inscriptions existantes', 'fa fa-list')
      )
      return tabs
    },
    canChange() {
      return this.hasPerm('youth.add_seanceinscription')
    },
  },
  async created() {
    this.tabChanged(this.tabs[0])
  },
  watch: {
    onlyForthcoming: function(value) {
      window.localStorage.setItem(onlyForthcomingStorageKey, '' + value)
    },
    includeCancelledActivities: function(value) {
      if (value) {
        window.localStorage.setItem(includeCancelledActivitiesStorageKey, '1')
      } else {
        window.localStorage.setItem(includeCancelledActivitiesStorageKey, '')
      }
      this.loadSynthesis()
    },
    reload: async function(value) {
      if (value) {
        await this.loadSynthesis()
        this.$emit('reloaded', {})
      }
    },
    synthesisFilter: function(synthesisFilter) {
      for (let filterName in synthesisFilter) {
        let filterValue = null
        if (synthesisFilter.hasOwnProperty(filterName)) {
          filterValue = synthesisFilter[filterName]
        }
        if (filterValue) {
          this.hasFilter = true
          return
        }
      }
      this.hasFilter = false
    },
    hasInscriptionsToBeConfirmed: function(newValue) {
      let editionName = 'seance-inscriptions'
      if (newValue) {
        this.setEditMode(editionName)
      } else {
        this.setEditMode('')
      }
    },
    newInscriptions: function() {
      this.onInscriptionsChanged()
      for (let inscription of this.newInscriptions) {
        let key = inscription.getKey()
        if (!this.selectedInscriptions.hasOwnProperty(key)) {
          this.selectedInscriptions[key] = true
          this.selectedInscriptions = { ...this.selectedInscriptions, }
        }
      }
    },
    cancellations: function() {
      this.onInscriptionsChanged()
      for (let inscription of this.cancellations) {
        let key = inscription.getKey()
        if (!this.selectedCancellations.hasOwnProperty(key)) {
          this.cancellations[key] = true
          this.selectedCancellations = { ...this.selectedCancellations, }
        }
      }
    },
    entity: function() {
      this.tabChanged(this.tabs[0])
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading', 'setEditMode']),
    onCancel() {
      this.$bvModal.show('bv-modal-cancel-inscriptions')
    },
    onSave(createInvoice) {
      this.createInvoice = createInvoice
      this.$bvModal.show('bv-modal-confirm-inscriptions')
    },
    onCancelInscriptions() {
      for (let seance of this.seances) {
        seance.resetChanges()
      }
      this.loadSynthesis()
    },
    async onConfirmInscriptions() {
      if (this.entity && this.entity.id > 0) {
        this.startLoading(this.seancesCreationLoading)
        let data = []
        for (let inscription of this.getSelectedCancellations()) {
          let workshops = inscription.seance.getOriginalWorkshopInscriptions(inscription.individual.id)
          data.push(
            {
              seance: inscription.seance.id,
              individual: inscription.individual.id,
              cancelled: true,
              refund: this.isCancellationRefund(inscription),
              discounts: [],
              workshops: workshops.map(elt => { return { workshop: elt.workshop, moment: elt.moment, } }),
            }
          )
        }
        for (let inscription of this.getSelectedInscriptions()) {
          let workshops = inscription.seance.getWorkshopInscriptions(inscription.individual.id)
          const discounts = this.getDiscount(inscription).map(
            discount => {
              if (discount.showPercentage) {
                return {
                  amount: 0, comments: discount.comments, percentage: discount.percentage,
                }
              } else {
                return {
                  amount: discount.amount, comments: discount.comments, percentage: 0,
                }
              }
            }
          )
          const insData = {
            seance: inscription.seance.id,
            individual: inscription.individual.id,
            cancelled: false,
            workshops: workshops.map(elt => { return { workshop: elt.workshop, moment: elt.moment, } }),
            discounts: discounts,
            waiting: this.isInscriptionWaitingList(inscription),
          }
          const multiplier = this.getMultiplier(inscription)
          if (multiplier) {
            insData.multiplier = multiplier
          }
          data.push(insData)
        }
        let url = '/api/youth/entity-create-inscriptions/' + this.entity.id + '/'
        if (this.createInvoice) {
          url += '?create-invoice=1'
        }
        let backendApi = new BackendApi('post', url)
        try {
          let resp = await backendApi.callApi(data)
          let updatedSeances = resp.data.seances.map(elt => makeEntitySeance(elt))
          let existingSeanceIds = this.seances.map(elt => elt.id)
          for (let updatedSeance of updatedSeances) {
            let seanceIndex = existingSeanceIds.indexOf(updatedSeance.id)
            if (seanceIndex >= 0) {
              let seance = this.seances[seanceIndex]
              seance.reinit(updatedSeance)
            }
          }
          this.seances = [].concat(this.seances)
          for (let seance of this.seances) {
            seance.resetChanges()
          }
          await this.addSuccess('Les inscriptions ont été prises en compte')
          await this.loadSynthesis()
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.endLoading(this.seancesCreationLoading)
      }
    },
    async calculateInscriptionsPrices(newInscriptions, cancellations) {
      if (this.entity && this.entity.id > 0) {
        this.startLoading(this.calculatePricesLoadingName)
        this.priceError = ''
        let data = []
        for (let inscription of newInscriptions) {
          data.push(
            {
              seance: inscription.seance.id,
              individual: inscription.individual.id,
              cancelled: false,
            }
          )
        }
        for (let inscription of cancellations) {
          data.push(
            {
              seance: inscription.seance.id,
              individual: inscription.individual.id,
              cancelled: true,
            }
          )
        }
        let url = '/api/youth/entity-seances-prices/' + this.entity.id + '/'
        let backendApi = new BackendApi('post', url)
        let prices = {}
        try {
          let resp = await backendApi.callApi(data)
          for (let elt of resp.data) {
            let priceKey = this.getPriceKey(elt.cancellation, elt.seance, elt.individual)
            prices[priceKey] = makeSeancePrice(elt)
          }
          this.allPrices = { ...prices, }
        } catch (err) {
          this.priceError = this.getErrorText(err)
          await this.addError(this.priceError)
        }
        this.forcePriceCalculation = false
        this.endLoading(this.calculatePricesLoadingName)
      }
    },
    onInscriptionsChanged() {
      this.forcePriceCalculation = true
      if (this.activeTab.name === 'inscriptions') {
        this.loadPrices()
      }
    },
    onNext() {
      if ((this.activeTab.name === 'seances') && this.hasInscriptionsToBeConfirmed) {
        this.tabChanged(this.tabs[1])
      }
    },
    onPrev() {
      if (this.activeTab.name !== 'seances') {
        this.tabChanged(this.tabs[0])
      }
    },
    onList() {
      if (this.activeTab.name !== 'existing') {
        this.tabChanged(this.tabs[2])
      }
    },
    tabChanged(tab) {
      this.activeTab = tab
      if (tab.name === 'seances') {
        if (!this.initDone) {
          this.loadSynthesis()
        }
      } else if (tab.name === 'inscriptions') {
        if (this.forcePriceCalculation) {
          this.loadPrices()
        }
      }
    },
    async loadPrices() {
      await this.calculateInscriptionsPrices(this.newInscriptions, this.cancellations)
      for (let cancellation of this.cancellations) {
        let key = cancellation.getKey()
        if (!this.refundCancellations.hasOwnProperty(key)) {
          this.refundCancellations[key] = !this.isInvoiced(cancellation)
        }
      }
      this.refundCancellations = { ...this.refundCancellations, }
    },
    filterChanged(filter) {
      this.synthesisFilter = { ...filter, }
      this.weekDaysFilters = filter.weekDays
      this.dayTimesFilters = filter.dayTimes
      this.dateFromFilter = filter.dateFrom
      this.dateToFilter = filter.dateTo
    },
    async loadSynthesis() {
      this.onlyForthcoming = +window.localStorage.getItem(onlyForthcomingStorageKey)
      this.includeCancelledActivities = !!window.localStorage.getItem(includeCancelledActivitiesStorageKey)
      if (this.entity && this.entity.id > 0) {
        this.initDone = true
        this.startLoading(this.synthesisLoading)
        let url = '/api/youth/seances-synthesis/' + this.entity.id + '/'
        if (this.includeCancelledActivities) {
          url += '?include_cancelled_activities=1'
        }
        let backendApi = new BackendApi('get', url)
        try {
          let resp = await backendApi.callApi()
          this.synthesisElements = resp.data.map((elt, index) => makeSeanceSynthesisElt(elt, index))
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.endLoading(this.synthesisLoading)
      }
    },
    onSeancesLoaded($event) {
      this.seances = this.seances.concat($event)
    },
    onInscriptionChanged(data) {
      this.disableIncludeCancelledActivities = true
      let seance = data.seance
      let individual = data.individual
      let inscription = data.inscription
      let key = this.getPriceKey(false, seance.id, individual.id)
      let individualId = +individual.id
      if (inscription && this.userDiscounts[individualId]) {
        this.allDiscounts[key] = { ...this.userDiscounts[individualId], }
      } else {
        this.allDiscounts[key] = null
      }
    },
    getSynthesisFilters() {
      let periods = []
      let seanceTypes = []
      let youthHomes = []
      let periodIds = []
      let seanceTypeIds = []
      let youthHomeIds = []
      if (this.synthesisElements) {
        for (let elt of this.synthesisElements) {
          if (periodIds.indexOf(elt.period.id) < 0) {
            periodIds.push(elt.period.id)
            periods.push(elt.period)
          }
          if (seanceTypeIds.indexOf(elt.seanceType.id) < 0) {
            seanceTypeIds.push(elt.seanceType.id)
            seanceTypes.push(elt.seanceType)
          }
          if (youthHomeIds.indexOf(elt.youthHome.id) < 0) {
            youthHomeIds.push(elt.youthHome.id)
            youthHomes.push(elt.youthHome)
          }
        }
      }
      return [
        { id: 'youthHome', name: this.youthHomeLabel, items: youthHomes, },
        { id: 'seanceType', name: 'Type de séance', items: seanceTypes, },
        { id: 'period', name: 'Période', items: periods, }
      ]
    },
    getPriceKey(cancellation, seanceId, individualId) {
      return (cancellation ? 'C-' : '') + seanceId + '/' + individualId
    },
    getPriceObj(cancellation, inscription) {
      let priceKey = this.getPriceKey(cancellation, inscription.seance.id, inscription.individual.id)
      if (priceKey in this.allPrices) {
        return this.allPrices[priceKey]
      }
      return null
    },
    getWorkshopPrice(inscription, workshopInscription) {
      let price = 0
      const workshop = inscription.seance.getWorkshop(workshopInscription.workshop)
      if (workshop && workshop.price) {
        const basePrice = workshop.price
        price = basePrice
        if (workshop.discountable) {
          for (const discount of this.getDiscount(inscription)) {
            if (discount.percentage) {
              price -= basePrice * discount.percentage / 100
            }
          }
        }
      }
      return price
    },
    getBasePrice(inscription) {
      let obj = this.getPriceObj(false, inscription)
      let price = obj ? obj.getPrice() : 0
      return price
    },
    getPriceTitle(inscription) {
      return 'Prix avant réduction: ' + currency(this.getBasePrice(inscription))
    },
    getCancelPrice(inscription) {
      // Prix d'annulation
      let obj = this.getPriceObj(true, inscription)
      let price = obj ? obj.getPrice() : 0
      return price
    },
    getPriceMessage(inscription) {
      let obj = this.getPriceObj(false, inscription)
      return obj ? obj.message : ''
    },
    getMultiplier(inscription) {
      return inscription.seance.getIndividualNewMultiplier(inscription.individual.id)
    },
    getMultiplierDelta(inscription) {
      return inscription.seance.getIndividualMultiplierDelta(inscription.individual.id)
    },
    getInitialMultiplier(inscription) {
      return inscription.seance.getIndividualInitialMultiplier(inscription.individual.id)
    },
    getFinalPrice(inscription) {
      let basePrice = this.getBasePrice(inscription)
      let multiplier = this.getMultiplierDelta(inscription)
      basePrice = basePrice * multiplier
      let price = basePrice
      for (const discount of this.getDiscount(inscription)) {
        if (discount.amount) {
          price -= discount.amount
        }
        if (discount.percentage) {
          price -= basePrice * discount.percentage / 100
        }
      }
      let workshopInscriptions = inscription.seance.getWorkshopInscriptions(inscription.individual.id)
      for (const wsIns of workshopInscriptions) {
        price += this.getWorkshopPrice(inscription, wsIns)
      }
      return price
    },
    isInvoiced(inscription) {
      let obj = this.getPriceObj(false, inscription)
      return (obj !== null) ? obj.isInvoiced() : false
    },
    getWelfare(inscription) {
      let priceKey = this.getPriceKey(false, inscription.seance.id, inscription.individual.id)
      if (priceKey in this.allPrices) {
        return this.allPrices[priceKey].welfare
      }
      return []
    },
    getWelfareSummary(inscription) {
      const welfare = this.getWelfare(inscription)
      const summary = []
      for (const item of welfare) {
        const index = summary.map(elt => elt.id).indexOf(item.id)
        if (item.amount && index >= 0) {
          summary[index].add(item)
        } else {
          summary.push(item.clone())
        }
      }
      return summary
    },
    getDiscount(inscription) {
      let discountKey = this.getPriceKey(false, inscription.seance.id, inscription.individual.id)
      const price = this.getBasePrice(inscription)
      if (price) {
        if (discountKey in this.allDiscounts) {
          let discount = this.allDiscounts[discountKey]
          if (discount) {
            return [discount]
          }
        }
      }
      return []
    },
    isCancellationRefund(cancellation) {
      let key = cancellation.getKey()
      if (this.refundCancellations.hasOwnProperty(key)) {
        return this.refundCancellations[key]
      }
      return false
    },
    changeCancellationRefund(cancellation, value) {
      this.refundCancellations[cancellation.getKey()] = value
      this.refundCancellations = { ...this.refundCancellations, }
    },
    isCancellationCharged(cancellation) {
      return !this.isCancellationRefund(cancellation)
    },
    changeCancellationCharged(cancellation, value) {
      this.changeCancellationRefund(cancellation, !value)
    },
    onDiscountsChanged(discounts) {
      this.userDiscounts = discounts
    },
    isCancellationSelected(cancellation) {
      let key = cancellation.getKey()
      if (this.selectedCancellations.hasOwnProperty(key)) {
        return this.selectedCancellations[key]
      }
      return true
    },
    isInscriptionSelected(inscription) {
      let key = inscription.getKey()
      if (this.selectedInscriptions.hasOwnProperty(key)) {
        return this.selectedInscriptions[key]
      }
      return true
    },
    isInscriptionWaitingList(inscription) {
      let key = inscription.getKey()
      if (this.waitingListInscriptions.hasOwnProperty(key)) {
        return this.waitingListInscriptions[key]
      }
      return false
    },
    selectCancellation(cancellation, value) {
      let key = cancellation.getKey()
      this.selectedCancellations[key] = value
      this.selectedCancellations = { ...this.selectedCancellations, }
    },
    selectInscription(inscription, value) {
      let key = inscription.getKey()
      this.selectedInscriptions[key] = value
      this.selectedInscriptions = { ...this.selectedInscriptions, }
    },
    setInscriptionOnWaitingList(inscription, value) {
      let key = inscription.getKey()
      this.waitingListInscriptions[key] = value
      this.waitingListInscriptions = { ...this.waitingListInscriptions, }
    },
    getSelectedInscriptions() {
      return this.newInscriptions.filter(elt => this.isInscriptionSelected(elt))
    },
    getSelectedCancellations() {
      return this.cancellations.filter(elt => this.isCancellationSelected(elt))
    },
    getSelectedInscriptionsCount() {
      return this.getSelectedInscriptions().length
    },
    getSelectedCancellationsCount() {
      return this.getSelectedCancellations().length
    },
    filteredSynthesisElements(allInscriptions = false) {
      let synthesisFilter = this.synthesisFilter
      let elements = this.synthesisElements
      if (synthesisFilter) {
        elements = elements.filter(elt => this.isSynthesisElementFiltered(elt, allInscriptions))
      }
      if (this.onlyForthcoming) {
        elements = elements.filter(this.isForthcomingFiltered)
      }
      return elements
    },
    isSynthesisElementVisible(elt, allInscriptions = false) {
      if (!this.isSynthesisElementFiltered(elt, allInscriptions)) {
        return false
      }
      if (!this.isForthcomingFiltered(elt)) {
        return false
      }
      return true
    },
    isSynthesisElementFiltered(elt, allInscriptions = false) {
      let synthesisFilter = this.synthesisFilter
      if (!allInscriptions && elt.seanceType.id === 0) {
        // Ne pas afficher Année complète sur les séances
        return false
      }
      if (synthesisFilter) {
        const text = synthesisFilter.text
        const words = text.split(' ')
        for (const word of words) {
          const textDontMatch = (
            (word) &&
            (!includes(elt.youthHome.name, word)) &&
            (!includes(elt.seanceType.name, word)) &&
            (!includes(elt.period.name, word))
          )
          if (textDontMatch) {
            return false
          }
        }
        const zeroFilter = synthesisFilter.zeroFilter
        if (zeroFilter) {
          if (allInscriptions && elt.allInscriptionsCount === 0) {
            return false
          }
          if (!allInscriptions && elt.inscriptionsCount === 0) {
            return false
          }
        }
        for (let filterName in synthesisFilter) {
          if (synthesisFilter.hasOwnProperty(filterName) && elt.hasOwnProperty(filterName)) {
            let filterVal = synthesisFilter[filterName]
            let eltVal = elt[filterName]
            if (filterVal && eltVal && eltVal.id && eltVal.id !== filterVal.id) {
              return false
            }
          }
        }
      }
      return true
    },
    onLimitsLoaded(event) {
      if (!this.hasLimits) {
        this.hasLimits = (
          (event.groupLimits.length > 0) || (event.dayLimits.length > 0) ||
          (event.seanceGroupLimits.length > 0) || (event.seanceLimits.length > 0) ||
          (event.excursionGroupLimits.length > 0) || (event.excursionLimits.length > 0)
        )
      }
      const limitsMap = new Map(this.limitsMap)
      const key = '' + event.seance.id + '/' + event.individual.id
      limitsMap.set(key, event)
      this.limitsMap = limitsMap
    },
    getLimits(inscription) {
      const seance = inscription.seance
      const individual = inscription.individual
      const key = '' + seance.id + '/' + individual.id
      if (this.limitsMap.has(key)) {
        return this.limitsMap.get(key)
      }
      return null
    },
    isForthcomingFiltered(elt) {
      const onlyForthcoming = +this.onlyForthcoming
      if (onlyForthcoming === 0) {
        return true
      }
      if ((onlyForthcoming === 3) && (elt.period.id > 0)) {
        return false
      }
      if (existsIn([onlyForthcoming], [1, 2, 4]) && (elt.period.id === 0)) {
        return false
      }
      if (elt.period.timeframes.length) {
        const timeframe = elt.period.timeframes[0]
        const today = moment().toDate()
        const periodEnd = new Date(timeframe.dateTo)
        const dateBefore = compareDates(periodEnd, today) < 0
        if ((onlyForthcoming === 1) && dateBefore) {
          return false
        }
        if ((onlyForthcoming === 2) && !dateBefore) {
          return false
        }
      }
      return true
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.message-warning {
  background: #f2f2a2;
  font-size: 1.2em;
  padding: 5px;
}
.btn-list {
  margin-right: 2px;
}
.small-cb {
  color: #888;
}
.multiplier {
  padding: 2px;
  background: #eee;
  border: solid 1px #ccc;
  font-size: 10px;
}
</style>
