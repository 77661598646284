<template>
<span class="add-adhesion-button" v-if="hasPerm('families.view_familylevelhistory')">
    <a href @click.prevent="showModal">
      <i class="fas fa-history"></i> Historique
    </a>
    <b-modal
      id="bv-modal-HistoricalQfModal"
      dialog-class="modal-lg"
      ok-title="Fermer"
      ok-only
    >
      <template v-slot:modal-title>
        <b><i class="fas fa-history"></i> Historique des quotients familiaux</b>
      </template>
        <div  v-if="histories.length === 0">
          Aucun quotient familial
        </div>
        <b-row class="row-line" v-if="histories.length">
          <b-col><b>Date</b></b-col>
          <b-col v-if="levels.length > 1"><b>Indice</b></b-col>
          <b-col><b>QF</b></b-col>
          <b-col><b>Modifié par</b></b-col>
        </b-row>
        <div v-for="history in histories" :key="history.id">
          <b-row v-if="history.order <= levels.length" class="row-history">
            <b-col>
              {{ history.updatedOn | dateToString }}
            </b-col>
            <b-col v-if="levels.length > 1">
              QF{{ history.order }}
            </b-col>
            <b-col>
              {{ history.value|defaultValue('non défini') }}
            </b-col>
            <b-col>
              {{ history.updatedBy }}
              <span v-if="!history.confirmed" class="badge badge-danger">
                à confirmer
              </span>
            </b-col>
          </b-row>
        </div>
    </b-modal>
  </span>
</template>

<script>
import { mapActions } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import { makeFamilyLevelHistory } from '@/types/families'
import { BackendApi } from '@/utils/http'

export default {
  name: 'HistoricalQfModal',
  mixins: [BackendMixin],
  props: {
    family: Object,
    levels: Array,
  },
  data() {
    return {
      histories: [],
    }
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    showModal() {
      this.$bvModal.show('bv-modal-HistoricalQfModal')
      this.getHistoryLevel()
    },
    async getHistoryLevel() {
      let url = '/api/family-level-history/' + this.family.id + '/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.histories = resp.data.map(elt => makeFamilyLevelHistory(elt))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
  },
}
</script>

<style scoped>
.row-history {
  padding: 5px;
}
</style>
