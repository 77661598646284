<template>
  <div v-if="entity !== null && entity.id && nursery && canView">
    <loading-gif :loading-name="loadingName"></loading-gif>
    <div class="field-group-block" v-if="!isLoading(loadingName)">
      <div v-if="individuals">
        <div class="content-headline">
          <b-row>
            <b-col>
              <div class="header-bar">
                <b-row>
                  <b-col md="8">
                    <b>Crèche</b>
                  </b-col>
                  <b-col md="4" class="text-right">
                    <a
                      class="btn btn-primary btn-sm"
                      href
                      @click.prevent="onNewContract"
                      v-if="activeTab.name === 'contracts' && !showNewContract && !readOnly  && canAddContract"
                      :class="editDisabled ? 'disabled' : ''"
                    >
                      <i class="fa fa-person-circle-plus"></i> Contrat
                    </a>
                    <a
                      class="btn btn-primary edit-button btn-sm"
                      href
                      @click.prevent="onEdit"
                      v-if="activeTab.name === 'family' && !showForm && !readOnly && !showNewContract && canChange"
                      :class="editDisabled ? 'disabled' : ''"
                    >
                      <i class="fa fa-edit"></i> Modifier
                    </a>
                    <a
                      v-if="activeTab.name === 'inscriptions'"
                      :class="hasXlsElements ? 'btn btn-secondary btn-sm' : 'btn btn-secondary btn-sm disabled'"
                      href
                      @click.prevent="pdfInvoicing()"
                    >
                      <i class="fas fa-file-pdf"></i> PDF
                    </a>
                    <a
                      v-if="activeTab.name === 'inscriptions'"
                      :class="hasXlsElements ? 'btn btn-secondary btn-sm' : 'btn btn-secondary btn-sm disabled'"
                      href
                      @click.prevent="excelInvoicing()"
                    >
                      <i class="fas fa-file-excel"></i> Excel
                    </a>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
        </div>
        <div class="field-line field-line-no-border">
          <tabs-bar
            :tabs="tabs"
            :active="activeTab"
            :enabled="!editDisabled"
            secondary
            @change="tabChanged($event)"
          ></tabs-bar>
        </div>
        <div v-if="nurseryFamily">
          <div v-if="activeTab.name === 'family'">
            <div v-if="nurseryFamily.id === 0" class="field-line">
              La famille n'est pas utilisatrice de la crèche
            </div>
            <div v-if="showForm">
              <b-form @submit.prevent="onSave" v-if="showForm">
                <div class="field-line">
                  <b-row>
                    <b-col>
                      <div v-for="revenue of revenues" :key="revenue.id">
                        <b-form-group
                          label-for="revenue"
                          :label="revenue.name"
                        >
                          <b-row>
                            <b-col>
                              <b-select
                                :id="'revenueType' + revenue.id"
                                v-model="revenue.revenueType"
                                @change="resetCalculations"
                              >
                                <b-select-option
                                  v-for="elt of revenueTypes"
                                  :key="elt.id"
                                  :value="elt.id"
                                >
                                  {{ elt.name }}
                                </b-select-option>
                              </b-select>
                            </b-col>
                            <b-col>
                              <decimal-input
                                :id="'revenue' + revenue.id"
                                v-model="revenue.revenue"
                                @change="resetCalculations"
                              >
                              </decimal-input>
                            </b-col>
                          </b-row>
                        </b-form-group>
                      </div>
                    </b-col>
                    <b-col>
                      <b-row>
                        <b-col>
                          <b-form-group label-for="childrenCount" label="Nb d'enfants">
                            <number-input
                              id="childrenCount"
                              v-model="childrenCount"
                              @change="resetCalculations"
                            ></number-input>
                          </b-form-group>
                        </b-col>
                        <b-col>
                          <b-form-group label-for="additionalSlice" label="Parts suppl." description="Enfant handicapé, ...">
                            <decimal-input
                              id="additionalSlice"
                              v-model="additionalSlice"
                              @change="resetCalculations"
                            ></decimal-input>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-form-group label-for="hasAdditionalPrice" label="" description="Enfant hors-communes, ...">
                        <b-form-checkbox
                          id="hasAdditionalPrice"
                          v-model="hasAdditionalPrice"
                          @change="resetCalculations"
                        >
                          Supplément
                        </b-form-checkbox>
                      </b-form-group>
                      <b-form-group label-for="familyLevel" label="QF">
                        <number-input
                          id="familyLevel"
                          v-model="familyLevel"
                          @change="resetCalculations"
                        ></number-input>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <div style="margin: 10px 0" class="text-right">
                        <a href @click.prevent="calculatePrices(true)" class="btn btn-primary">
                          <i class="fa fa-calculator"></i> Calculer
                        </a>
                      </div>
                      <loading-gif :loading-name="calculatingName"></loading-gif>
                      <div v-if="calculatedFamily">
                        <div class="field-line" v-if="calculatedFamily.emergency">
                          <span><i class="fa fa-warning"></i> Accueil d'urgence</span>
                        </div>
                        <div class="field-line" v-else>
                          <span><i class="fa fa-percentage"></i> Taux d'effort: </span>
                          {{ calculatedFamily.rate | percentage }}
                        </div>
                        <div class="field-line" v-if="calculatedFamily.additionalRate">
                          <span><i class="fa fa-percentage"></i> Participation supplémentaire: </span>
                          {{ calculatedFamily.additionalRate | percentage }}
                        </div>
                        <div class="field-line">
                          <span><i class="fa fa-euro-sign"></i> Revenu moyen calculé: </span>
                          {{ calculatedFamily.rawMonthlyRevenue | currency }}
                        </div>
                        <div class="field-line">
                          <span><i class="fa fa-euro-sign"></i> Revenu moyen pris en compte: </span>
                          {{ calculatedFamily.monthlyRevenue | currency  }}
                        </div>
                        <div class="field-line">
                          <span><i class="fa fa-clock-four"></i> Participation familiale: </span>
                          {{ calculatedFamily.hourlyPrice | currency  }}
                        </div>
                        <div class="field-line" v-if="calculatedFamily.additionalHourlyPrice">
                          <span><i class="fa fa-clock-four"></i> Supplément: </span>
                          {{ calculatedFamily.additionalHourlyPrice | currency  }}
                        </div>
                        <div class="field-line">
                          <span><i class="fa fa-euro"></i> Tarif garde: </span>
                          {{ calculatedFamily.childPrice | currency }}
                        </div>
                        <div class="field-line">
                          <span><i class="fa fa-cutlery"></i> Tarif repas: </span>
                          {{ calculatedFamily.lunchPrice | currency  }}
                        </div>
                        <div class="field-line" v-if="calculatedFamily.additionalPrice">
                          <span><i class="fa fa-euro"></i> Supplément: </span>
                          {{ calculatedFamily.additionalPrice | currency }}
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </div>
                <b-row class="buttons-bar">
                  <b-col class="text-left">
                  </b-col>
                  <b-col class="text-right">
                    <a class="btn btn-secondary btn-cancel" href @click.prevent="onCancel">Annuler</a>
                    <b-button type="submit" variant="primary" :disabled="!calculatedFamily">Enregistrer</b-button>
                  </b-col>
                </b-row>
              </b-form>
            </div>
            <div v-else>
              <b-row>
                <b-col>
                  <div class="field-line">
                    <b>Enfants jusqu'à 4 ans</b>
                  </div>
                  <div class="field-line" v-if="nurseryFamily.emergency">
                    <span><i class="fa fa-warning"></i> Accueil d'urgence</span>
                  </div>
                  <div class="field-line" v-else>
                    <span><i class="fa fa-percentage"></i> Taux d'effort: </span>
                    {{ nurseryFamily.rate | percentage }}
                  </div>
                  <div class="field-line" v-if="nurseryFamily.additionalRate">
                    <span><i class="fa fa-percentage"></i> Participation supplémentaire: </span>
                    {{ nurseryFamily.additionalRate | percentage }}
                  </div>
                  <div class="field-line" v-if="!nurseryFamily.emergency">
                    <span><i class="fa fa-euro-sign"></i> Revenu moyen calculé: </span>
                    {{ nurseryFamily.rawMonthlyRevenue | currency }}
                  </div>
                  <div class="field-line" v-if="!nurseryFamily.emergency">
                    <span><i class="fa fa-euro-sign"></i> Revenu moyen pris en compte: </span>
                    {{ nurseryFamily.monthlyRevenue | currency  }}
                  </div>
                  <div class="field-line">
                    <span><i class="fa fa-clock-four"></i> Participation familiale: </span>
                    {{ nurseryFamily.hourlyPrice | currency  }}
                  </div>
                  <div class="field-line" v-if="!nurseryFamily.additionalHourlyPrice">
                    <span><i class="fa fa-clock-four"></i> Supplément: </span>
                    {{ nurseryFamily.additionalHourlyPrice | currency  }}
                  </div>
                </b-col>
                <b-col>
                  <div class="field-line">
                    <b>Enfants de plus de 4 ans</b>
                  </div>
                  <div class="field-line">
                    <span><i class="fa fa-list-numeric"></i> QF: </span>
                    {{ nurseryFamily.familyLevel }}
                  </div>
                  <div class="field-line">
                    <span><i class="fa fa-euro"></i> Tarif garde: </span>
                    {{ nurseryFamily.childPrice | currency }}
                  </div>
                  <div class="field-line">
                    <span><i class="fa fa-cutlery"></i> Tarif repas: </span>
                    {{ nurseryFamily.lunchPrice | currency  }}
                  </div>
                  <div class="field-line" v-if="+nurseryFamily.additionalPrice">
                    <span><i class="fa fa-euro"></i> Supplément: </span>
                    {{ nurseryFamily.additionalPrice | currency }}
                  </div>
                </b-col>
                <b-col>
                  <div class="field-line">
                    <span>
                      <i class="fa fa-children"></i> Nb d'enfants:
                    </span> {{ nurseryFamily.childrenCount }}
                  </div>
                  <div class="field-line">
                    <span>
                      <i class="fa fa-user-plus"></i> Part supplémentaires:
                    </span> {{ nurseryFamily.additionalSlice }}
                  </div>
                </b-col>
                <b-col>
                  <div class="field-line" v-if="nurseryFamily.ratesHistory">
                    <b>Historique</b>
                  </div>
                  <div
                    v-for="elt of nurseryFamily.ratesHistory"
                    :key="elt.id"
                    class="field-line"
                  >
                    <span>{{ elt.createdOn | dateToString }}:</span> {{ elt.rate | percentage }}
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
        <div v-if="activeTab.name === 'contracts'">
          <div v-if="showNewContract">
            <b-form @submit.prevent="onAddNewContract">
              <div class="field-line">
                <b-row>
                  <b-col>
                    <b-form-group label="Crèche" label-for="selectedNursery">
                      <b-select v-model="selectedNursery" id="selectedNursery" :disabled="nurseries.length === 1">
                        <b-select-option
                          v-for="elt of nurseries"
                          :value="elt"
                          :key="elt.id"
                        >
                          {{ elt.name }}
                        </b-select-option>
                      </b-select>
                    </b-form-group>
                  </b-col>
                  <b-col cols="4">
                    <b-form-group label="Type" label-for="contractType">
                      <b-select v-model="contractType" id="contractType">
                        <b-select-option
                          v-for="elt of contractTypes"
                          :value="elt.id"
                          :key="elt.id"
                        >
                          {{ elt.name }}
                        </b-select-option>
                      </b-select>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="5">
                    <b-form-group label="Enfant" label-for="selectedChild">
                      <b-select v-model="selectedChild" id="selectedChild">
                        <b-select-option
                          v-for="child of individuals"
                          :value="child"
                          :key="child.id"
                        >
                          {{ child.firstAndLastName() }}
                        </b-select-option>
                      </b-select>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group label="Du" label-for="contractStartDate">
                      <b-form-input v-model="contractStartDate" id="contractStartDate" type="date">
                      </b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group label="au" label-for="contractEndDate">
                      <b-form-input v-model="contractEndDate" id="contractEndDate" type="date">
                      </b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <br />
                    <div class="small-text">
                      <a href @click.prevent="forceDates(true, '01-01', '08-31')">
                        1er janv. - 31 août
                      </a>
                    </div>
                    <div class="small-text">
                      <a href @click.prevent="forceDates(false, '09-01', '12-31')">
                        1er sept. - 31 dec.
                      </a>
                    </div>
                  </b-col>
                </b-row>
              </div>
              <div v-if="needsDailyGrids">
                <nursery-inscription-grid @change="onGridChanged"></nursery-inscription-grid>
              </div>
              <b-row class="buttons-bar">
                <b-col class="text-left">
                </b-col>
                <b-col class="text-right">
                  <a class="btn btn-secondary btn-cancel" href @click.prevent="onCancel">Annuler</a>
                  <b-button type="submit" variant="primary" :disabled="!isContractValid()">Ajouter</b-button>
                </b-col>
              </b-row>
            </b-form>
          </div>
          <div v-else>
            <div class="field-line">
              <b-row>
                <b-col>
                  <check-box-select
                    v-if="children.length"
                    :choices="children"
                    :initial-value="children"
                    @init="selectedChildren=$event.choices"
                    @changed="selectedChildren=$event.choices"
                    :name-callback="ind => ind.firstName"
                    inline
                  ></check-box-select>
                </b-col>
                <b-col cols="2" class="small-text text-right">
                  <div v-if="children.length">
                    <b-form-select class="small-select" v-model="contractFilter">
                      <b-select-option :value="3">Actifs</b-select-option>
                      <b-select-option :value="1">En cours</b-select-option>
                      <b-select-option :value="2">Futurs</b-select-option>
                      <b-select-option :value="4">Passés</b-select-option>
                      <b-select-option :value="0">Tous</b-select-option>
                    </b-form-select>
                  </div>
                </b-col>
                <b-col cols="2" class="text-right small-text">
                  <div v-if="children.length && hasCancelledContracts">
                    <b-form-checkbox id="cancelledContracts" v-model="cancelledContracts">
                      Voir les annulations
                    </b-form-checkbox>
                  </div>
                </b-col>
              </b-row>
              <div>
                <div class="empty-text" v-if="allContracts.length === 0">
                  Aucun contrat
                </div>
                <div class="empty-text" v-else-if="filteredContracts.length === 0">
                  Aucun contrat pour le filtre sélectionné
                </div>
                <div v-else>
                  <div v-for="contract of filteredContracts" :key="contract.id" class="field-line">
                    <div class="row-line">
                      <b-row>
                       <b-col cols="4">
                         {{ contract.individual.lastAndFirstName() }}
                         <div class="small-text" title="âge au début du contrat" v-b-tooltip>
                           {{ contract.individual.getBabyAgeOn(contract.startDate) }}
                         </div>
                         <nursery-contract-download :contract="contract">
                         </nursery-contract-download>
                         <div style="margin-top: 10px"></div>
                         <nursery-contract-summary-download :contract="contract">
                         </nursery-contract-summary-download>
                       </b-col>
                       <b-col>
                         <b-row>
                           <b-col cols="6">
                             <span class="badge badge-light badge-max">{{ contract.nursery.getLabel() }}</span>
                           </b-col>
                           <b-col cols="6">
                             <span class="badge badge-dark badge-max">
                               du
                               {{ contract.startDate | dateToString }}
                               -
                               {{ contract.endDate | dateToString }}
                             </span>
                             <span class="badge badge-primary badge-max">
                              {{ contract.getCycle() }}
                              <div v-if="contract.getHolidaysCycle()">{{ contract.getHolidaysCycle() }}</div>
                             </span>
                           </b-col>
                         </b-row>
                         <div>
                           <b-row>
                             <b-col v-if="contract.hasHourlyPrice()">
                               <span class="badge badge-dark">
                                 <i class="fa fa-clock-four"></i>
                                 Participation familiale:
                                 {{ contract.getHourlyPrice() | currency }}/h
                               </span>
                             </b-col>
                             <b-col v-if="contract.hasChildPrice()">
                               <span class="badge badge-dark" >
                                 <i class="fa fa-child"></i>
                                 Plus de 4 ans:
                                 {{ contract.getChildPrice() | currency }}
                               </span>
                             </b-col>
                             <b-col>
                               <span v-if="contract.hasLunchPrice()" class="badge badge-dark" >
                                 <i class="fa fa-cutlery"></i>
                                 Repas:
                                 {{ contract.lunchPrice | currency }}
                               </span>
                             </b-col>
                           </b-row>
                         </div>
                         <div
                           v-for="update of contract.updates"
                           :key="update.id" class="update-line field-line"
                         >
                           <div class="small-text">
                            Avenant du {{ update.createdOn | dateToString }}
                            <br />
                              Du {{ update.startDate | dateToString }}
                             au {{ update.endDate | dateToString }}
                           </div>
                           <div v-if="update.hourlyPrice">
                            <span class="badge badge-dark">
                              <i class="fa fa-clock-four"></i>
                              Participation familiale:
                              {{ update.hourlyPrice | currency }}/h
                            </span>
                          </div>
                          <div v-if="contract.hasChildPrice()">
                            <span class="badge badge-dark" >
                              <i class="fa fa-child"></i>
                              Plus de 4 ans:
                              {{ update.childPrice | currency }}
                            </span>
                          </div>
                          <div v-if="contract.hasLunchPrice()">
                           <span class="badge badge-dark" >
                             <i class="fa fa-cutlery"></i>
                             Repas:
                             {{ update.lunchPrice | currency }}
                           </span>
                          </div>
                         </div>
                         <div v-if="editContractHours">
                           <nursery-inscription-grid :contract=selectedContract @change="onGridChanged2"></nursery-inscription-grid>
                           <b-row>
                             <b-col></b-col>
                             <b-col cols="5" class="text-right">
                               <a class="btn btn-sm btn-secondary" href @click.prevent="editContractHours = false">
                                 <i class="fa fa-close"></i> Annuler
                               </a>
                               &nbsp;
                               <a class="btn btn-sm btn-primary" href @click.prevent="onUpdateContractHours()">
                                 <i class="fa fa-save"></i> Enregistrer
                               </a>
                             </b-col>
                           </b-row>
                         </div>
                         <div v-else>
                           <div v-for="item of contract.items" :key="item.id" class="field-line">
                             <b-row>
                               <b-col cols="3">
                                 <span
                                   class="badge"
                                   :class="!item.holidays ? 'badge-dark' : 'badge-danger'">
                                   {{ item.getDayName() }}
                                 </span>
                               </b-col>
                               <b-col cols="4">
                                 <span class="badge" :class="!item.holidays ? 'badge-dark' : 'badge-danger'">
                                   {{ displayHourMin(item.arrivalAt) }} - {{ displayHourMin(item.leavingAt) }}
                                 </span>
                               </b-col>
                               <b-col>
                                 <span
                                   class="badge"
                                   :class="!item.holidays ? 'badge-dark' : 'badge-danger'"
                                   v-if="(contract.weeksCycle > 1) || (item.holidays && contract.holidaysCycle > 0)"
                                 >
                                   {{ item.getWeekInfo() }}
                                 </span>
                               </b-col>
                             </b-row>
                           </div>
                         </div>
                       </b-col>
                       <b-col cols="2" class="text-right">
                         <div class="badge badge-dark">
                          {{ contract.getContractTypeLabel() }}
                         </div>
                         <div class="small-text">
                           <counter-label :counter="contract.inscriptionsCount" label="inscription">
                           </counter-label>
                         </div>
                         <div v-if="contract.cancelledOn">
                           <div class="badge badge-danger">
                             Annulé le {{ contract.cancelledOn | dateToString  }}
                           </div>
                           <div v-if="contract.cancelledBy" class="small-text">
                             par {{ contract.cancelledBy }}
                           </div>
                           <div v-if="contract.cancelInscriptionFrom" class="small-text">
                             inscriptions annulées à partir du
                             {{ contract.cancelInscriptionFrom | dateToString  }}
                           </div>
                         </div>
                         <div v-else-if="canCancelContract">
                           <a href @click.prevent="cancelContract(contract)" class="btn btn-secondary btn-xs">
                             <i class="fa fa-edit"></i> Modifier le contrat
                           </a>
                           <a href @click.prevent="cancelContractHours(contract)" class="btn btn-secondary btn-xs">
                             <i class="fa fa-clock"></i> Modifier les horaires
                           </a>
                           <div v-if="contract.modifiedOn" class="small2">
                             Modifié le {{ contract.modifiedOn|dateToString }}
                           </div>
                         </div>
                       </b-col>
                     </b-row>
                    </div>
                    <div v-if="!editContractHours">
                      <entity-create-nursery-inscriptions :contract="contract" @done="loadContracts">
                      </entity-create-nursery-inscriptions>
                    </div>
                  </div>
                  <table class="hide-here">
                    <tbody v-for="contract of filteredContracts" :key="contract.id">
                      <tr>
                        <th>
                          {{ contract.individual.lastAndFirstName() }}
                        </th>
                        <th>
                          {{ contract.individual.getBabyAgeOn(contract.startDate) }}
                        </th>
                        <th>
                          {{ contract.startDate | dateToString }}
                        </th>
                        <th>
                          {{ contract.endDate | dateToString }}
                        </th>
                        <th>
                          {{ contract.getContractTypeLabel() }}
                        </th>
                      </tr>
                      <tr v-for="item of contract.items" :key="item.id">
                        <td>{{ getDayName(item.day) }}</td>
                        <td>{{ displayHourMin(item.arrivalAt) }}</td>
                        <td>{{ displayHourMin(item.leavingAt) }}</td>
                        <td>{{ item.hourlyPrice | currency }}</td>
                        <td>{{ item.lunchPrice | currency }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="activeTab.name === 'inscriptions'" ref="excelInscriptions">
          <entity-nursery-inscriptions
            :nurseries="nurseries"
            :entity="entity"
            @changed="hasXlsElements = $event.months"
          >
          </entity-nursery-inscriptions>
        </div>
      </div>
    </div>
    <nursery-cancel-contract-modal
      id="bv-nursery-cancel-contract-modal"
      :contract="selectedContract"
      @confirmed="onCancelContractConfirmed($event)"
    ></nursery-cancel-contract-modal>
  </div>
</template>

<script>
import moment from 'moment'
import { mapMutations, mapActions } from 'vuex'
import LoadingGif from '@/components/Controls/LoadingGif'
import DecimalInput from '@/components/Controls/DecimalInput.vue'
import NumberInput from '@/components/Controls/NumberInput.vue'
import CheckBoxSelect from '@/components/Controls/CheckBoxSelect.vue'
import TabsBar from '@/components/Controls/TabsBar.vue'
import CounterLabel from '@/components/Controls/CounterLabel.vue'
import EntityNurseryInscriptions from '@/components/Entity/EntityNurseryInscriptions.vue'
import EntityCreateNurseryInscriptions from '@/components/Entity/EntityCreateNurseryInscriptions.vue'
import NurseryInscriptionGrid from '@/components/Entity/NurseryInscriptionGrid.vue'
import NurseryCancelContractModal from '@/components/Nursery/NurseryCancelContractModal.vue'
import NurseryContractDownload from '@/components/Nursery/NurseryContractDownload.vue'
import NurseryContractSummaryDownload from '@/components/Nursery/NurseryContractSummaryDownload.vue'
import { BackendMixin } from '@/mixins/backend'
import store from '@/store'
import {
  NurseryContractType, getDayName, makeNurseryContract, makeNurseryFamily, makeNurseryFamilyRevenu, RevenueType,
  getContractTypeLabel, makeNursery
} from '@/types/nursery'
import { makeChoice } from '@/types/base'
import { TabItem } from '@/types/tabs'
import { distinct, existsIn } from '@/utils/arrays'
import { BackendApi, openDocument } from '@/utils/http'
import { displayHourMin } from '@/utils/time'
import { slugify } from '@/utils/strings'

export default {
  name: 'entity-nursery',
  components: {
    NurseryContractDownload,
    NurseryContractSummaryDownload,
    NurseryCancelContractModal,
    NurseryInscriptionGrid,
    CounterLabel,
    EntityNurseryInscriptions,
    EntityCreateNurseryInscriptions,
    TabsBar,
    CheckBoxSelect,
    NumberInput,
    DecimalInput,
    LoadingGif,
  },
  mixins: [BackendMixin],
  props: {
    entity: Object,
    member: Object,
    readOnly: Boolean,
  },
  data() {
    return {
      loadingName: 'entity-nursery',
      componentName: 'entity-nursery',
      loadingCancelName: 'cancel-nursery-contract',
      nurseryFamily: null,
      calculatedFamily: null,
      revenues: [],
      childrenCount: 1,
      additionalSlice: 0,
      familyLevel: 0,
      revenueTypes: [],
      hasAdditionalPrice: false,
      emergency: false,
      calculatingName: 'calc-entity-nursery',
      allContracts: [],
      selectedChildren: [],
      contractFilter: 3,
      cancelledContracts: false,
      showNewContract: false,
      selectedChild: null,
      contractStartDate: null,
      contractEndDate: null,
      contractType: 0,
      activeTab: null,
      hasXlsElements: 0,
      nurseries: [],
      selectedNursery: null,
      isGridValid: false,
      weeksCycle: 0,
      holidaysCycle: 0,
      days: [],
      selectedContract: null,
      editContractHours: false,
    }
  },
  computed: {
    nursery() {
      return store.getters.config.nursery
    },
    showForm() {
      return (store.getters.editMode === this.componentName)
    },
    editDisabled() {
      return (store.getters.editMode !== '' && store.getters.editMode !== this.componentName)
    },
    individuals() {
      return this.entity.getChildrenIndividuals()
    },
    children() {
      return distinct(this.allContracts.map(elt => elt.individual))
    },
    contracts() {
      return this.allContracts.filter(
        elt => existsIn([elt.individual.id], this.selectedChildren.map(child => child.id))
      ).filter(
        elt => {
          if (+this.contractFilter === 1) {
            return elt.isActive()
          }
          if (+this.contractFilter === 2) {
            return elt.isNext()
          }
          if (+this.contractFilter === 3) {
            return elt.isActive() || elt.isNext()
          }
          if (+this.contractFilter === 4) {
            return elt.isFinished()
          }
          return true
        }
      )
    },
    hasCancelledContracts() {
      return this.contracts.filter(
        elt => elt.cancelledOn !== null
      ).length > 0
    },
    filteredContracts() {
      let contracts = this.contracts
      if (!this.cancelledContracts) {
        contracts = contracts.filter(
          elt => elt.cancelledOn === null
        )
      }
      return contracts
    },
    canCancelContract() {
      return this.hasPerm('nursery.change_nurserycontract') && !this.editContractHours
    },
    canView() {
      return this.hasOnePerm(
        ['nursery.view_nurseryfamily', 'nursery.view_nurserycontract', 'nursery.view_nurseryinscription']
      )
    },
    canChange() {
      return this.hasPerm('nursery.change_nurseryfamily')
    },
    canAddContract() {
      return this.hasPerm('nursery.add_nurserycontract') && this.nurseryFamily && this.nurseryFamily.id
    },
    contractTypes() {
      return [
        NurseryContractType.Regular, NurseryContractType.TimeToTime, NurseryContractType.Changing
      ].map(
        elt => makeChoice({ id: elt, name: getContractTypeLabel(elt), })
      )
    },
    tabs() {
      const tabs = []
      if (this.hasPerm('nursery.view_nurseryfamily')) {
        tabs.push(new TabItem('family', 'Famille', 'fas fa-people-roof'))
      }
      if (this.hasPerm('nursery.view_nurserycontract')) {
        tabs.push(new TabItem('contracts', 'Contrats', 'fa fa-calendar-alt'))
      }
      if (this.hasPerm('nursery.view_nurseryinscription')) {
        tabs.push(new TabItem('inscriptions', 'Inscriptions', 'fa fa-list'))
      }
      return tabs
    },
    needsDailyGrids() {
      return this.contractType === NurseryContractType.Regular
    },
  },
  watch: {
    entity: function() { this.onCreated() },
    readOnly: function() {},
    contractType: function() {
    },
  },
  async created() {
    this.revenueTypes = [
      makeChoice({ id: RevenueType.Yearly, name: 'Annuel', }),
      makeChoice({ id: RevenueType.Monthly, name: 'Mensuel', })
    ]
    await this.onCreated()
    await this.loadNurseries()
  },
  methods: {
    getDayName,
    displayHourMin,
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading', 'setEditMode']),
    async onCreated() {
      await this.tabChanged(this.tabs[0])
      if (this.individuals.length === 1) {
        this.selectedChild = this.individuals[0]
      }
    },
    async tabChanged(tab) {
      this.activeTab = tab
      if (tab.name === 'family') {
        await this.loadEntityNursery()
      } else if (tab.name === 'contracts') {
        await this.loadContracts()
      } else if (tab.name === 'inscriptions') {
      }
    },
    onEdit() {
      if (!this.editDisabled) {
        this.calculatedFamily = null
        this.setEditMode(this.componentName)
      }
    },
    onCancel() {
      this.calculatedFamily = null
      this.showNewContract = false
      this.setEditMode('')
    },
    init() {
      if (this.nurseryFamily && this.nurseryFamily.id) {
        this.childrenCount = this.nurseryFamily.childrenCount
        this.additionalSlice = this.nurseryFamily.additionalSlice
        this.familyLevel = this.nurseryFamily.familyLevel
        this.revenues = this.nurseryFamily.revenues.map(elt => elt.clone())
        this.hasAdditionalPrice = this.nurseryFamily.hasAdditionalPrice
        this.emergency = this.nurseryFamily.emergency
      } else {
        this.childrenCount = 1
        this.additionalSlice = 0
        this.revenues = [
          makeNurseryFamilyRevenu({ id: -1, name: 'Revenu de Madame', }),
          makeNurseryFamilyRevenu({ id: -2, name: 'Revenu de Monsieur', }),
          makeNurseryFamilyRevenu({ id: -3, name: 'Autres revenus', })
        ]
        this.hasAdditionalPrice = false
        this.emergency = false
      }
    },
    async onSave() {
      await this.calculatePrices(false)
      this.setEditMode('')
    },
    resetCalculations() {
      this.calculatedFamily = null
    },
    async calculatePrices(dry) {
      if (this.entity.id) {
        let backendApi = new BackendApi('post', '/nursery/api/family/' + this.entity.id + '/')
        this.startLoading(this.calculatingName)
        try {
          const revenues = this.revenues.map(
            elt => {
              return {
                id: elt.id > 0 ? elt.id : 0,
                name: elt.name,
                revenue: elt.revenue,
                revenue_type: elt.revenueType,
              }
            }
          )
          const data = {
            dry: dry,
            revenues: revenues,
            children_count: this.childrenCount,
            additional_slice: this.additionalSlice,
            has_additional_price: this.hasAdditionalPrice,
            emergency: this.emergency,
            family_level: this.familyLevel,
          }
          let resp = await backendApi.callApi(data)
          const nurseryFamily = makeNurseryFamily(resp.data)
          if (dry) {
            this.calculatedFamily = nurseryFamily
          } else {
            this.nurseryFamily = nurseryFamily
          }
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.endLoading(this.calculatingName)
      }
    },
    async loadEntityNursery() {
      if (this.entity.id) {
        let backendApi = new BackendApi('get', '/nursery/api/family/' + this.entity.id + '/')
        this.startLoading(this.loadingName)
        try {
          let resp = await backendApi.callApi()
          this.nurseryFamily = makeNurseryFamily(resp.data)
          this.init()
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.endLoading(this.loadingName)
      } else {
        this.nurseryFamily = null
      }
    },
    async loadContracts() {
      if (this.entity) {
        let url = '/nursery/api/entity-contracts/' + this.entity.id + '/'
        const backendApi = new BackendApi('get', url)
        try {
          const resp = await backendApi.callApi()
          this.allContracts = resp.data.map(elt => makeNurseryContract(elt))
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    async loadNurseries() {
      if (this.entity) {
        let url = '/nursery/api/nurseries/'
        const backendApi = new BackendApi('get', url)
        try {
          const resp = await backendApi.callApi()
          this.nurseries = resp.data.map(elt => makeNursery(elt))
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    onNewContract() {
      this.showNewContract = true
      if (this.nurseries.length === 1) {
        this.selectedNursery = this.nurseries[0]
      }
    },
    async onAddNewContract() {
      if (this.isContractValid()) {
        let url = '/nursery/api/entity-contracts/' + this.entity.id + '/'
        const backendApi = new BackendApi('post', url)
        const items = []
        if (this.contractType === NurseryContractType.Regular) {
          for (const day of this.days.filter(elt => elt.selected)) {
            items.push(
              {
                day: day.dayId,
                arrival_at: day.arrivalAt,
                leaving_at: day.leavingAt,
                lunch: day.lunch,
                track: 0,
                week_index: day.weekIndex(),
                holidays: day.holidays(),
              }
            )
            if (day.tracks) {
              items.push(
                {
                  day: day.dayId,
                  arrival_at: day.arrivalAt2,
                  leaving_at: day.leavingAt2,
                  lunch: false,
                  track: 1,
                  week_index: day.weekIndex(),
                  holidays: day.holidays(),
                }
              )
            }
          }
        }
        const data = {
          nursery: this.selectedNursery.id,
          individual: this.selectedChild.id,
          start_date: this.contractStartDate,
          end_date: this.contractEndDate,
          items: items,
          contract_type: this.contractType,
          weeks_cycle: this.weeksCycle,
          holidays_cycle: this.holidaysCycle,
        }
        try {
          const resp = await backendApi.callApi(data)
          this.allContracts = [makeNurseryContract(resp.data)].concat(this.allContracts)
          this.showNewContract = false
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    async onUpdateContractHours() {
      if (this.isGridValid) {
        let url = '/nursery/api/update-contract/' + this.selectedContract.id + '/'
        const backendApi = new BackendApi('post', url)
        const items = []
        for (const day of this.days.filter(elt => elt.selected)) {
          items.push(
            {
              day: day.dayId,
              arrival_at: day.arrivalAt,
              leaving_at: day.leavingAt,
              lunch: day.lunch,
              track: 0,
              week_index: day.weekIndex(),
              holidays: day.holidays(),
            }
          )
          if (day.tracks) {
            items.push(
              {
                day: day.dayId,
                arrival_at: day.arrivalAt2,
                leaving_at: day.leavingAt2,
                lunch: false,
                track: 1,
                week_index: day.weekIndex(),
                holidays: day.holidays(),
              }
            )
          }
        }
        const data = {
          items: items,
          weeks_cycle: this.weeksCycle,
          holidays_cycle: this.holidaysCycle,
        }
        try {
          const resp = await backendApi.callApi(data)
          const updatedContract = makeNurseryContract(resp.data)
          const index = this.allContracts.map(elt => elt.id).indexOf(updatedContract.id)
          if (index >= 0) {
            this.allContracts[index] = updatedContract
          } else {
            this.allContracts.push(updatedContract)
          }
          this.allContracts = [].concat(this.allContracts)
          this.editContractHours = false
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    isContractValid() {
      let isValid = (
        this.selectedNursery &&
        this.selectedChild &&
        this.contractStartDate &&
        this.contractEndDate &&
        this.contractType
      )
      if (isValid && this.contractType === NurseryContractType.Regular) {
        isValid = this.isGridValid
      }
      return isValid
    },
    async excelInvoicing() {
      const docUrl = '/documents/table-to-excel/<key>/'
      const docSlug = slugify(this.activeTab.label + '-' + this.entity.name)
      const docContent = this.$refs.excelInscriptions.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async pdfInvoicing() {
      const docUrl = '/documents/standard/<key>/pdf/'
      const docSlug = slugify(this.activeTab.label + '-' + this.entity.name)
      const docContent = this.$refs.excelInscriptions.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    onGridChanged(event) {
      this.weeksCycle = event.weeksCycle
      this.holidaysCycle = event.holidaysCycle
      this.isGridValid = event.isValid
      this.days = event.days
    },
    onGridChanged2(event) {
      this.weeksCycle = event.weeksCycle
      this.holidaysCycle = event.holidaysCycle
      this.isGridValid = event.isValid
      this.days = event.days
    },
    forceDates(start, dt1, dt2) {
      const currentYear = moment().year()
      const currentMonth = moment().month()
      let year = currentYear
      if (start && currentMonth > 8) {
        year += 1
      }
      this.contractStartDate = '' + year + '-' + dt1
      this.contractEndDate = '' + year + '-' + dt2
    },
    cancelContract(contract) {
      this.selectedContract = contract
      this.$bvModal.show('bv-nursery-cancel-contract-modal')
    },
    cancelContractHours(contract) {
      this.selectedContract = contract
      this.editContractHours = true
    },
    async onCancelContractConfirmed() {
      this.selectedContract = null
      await this.loadContracts()
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.update-line {
  background: #ffcc77;
}
</style>
