<template>
  <div class="entity" v-if="canView">
    <page-header
      :title="title"
      :links="getLinks()"
      :icon="isFamily ? 'fa-solid fa-people-roof' : 'fa fa-address-book'"
    ></page-header>
    <b-row>
      <b-col>
        <tabs-bar
          :enabled="!selectorDisabled"
          :tabs="entityTabs"
          :active="activeTab"
          @change="onTabChanged($event)"
        >
        </tabs-bar>
      </b-col>
      <b-col cols="1" class="text-right">
        <a
          href
          @click.prevent="toggleMembers()"
          :class="{ disabled: member !== null, }"
          :title="areMembersVisible ? 'Cacher les membres' : 'Voir les membres'"
          v-b-tooltip
          class="btn btn-sm btn-secondary"
        >
          <i class="fa fa-users-slash" v-if="areMembersVisible"></i>
          <i class="fa fa-users" v-else></i>
        </a>
      </b-col>
    </b-row>

    <div v-if="!isFamily" class="page-header-spacer"></div>
    <loading-gif :loading-name="loadingName"></loading-gif>
    <b-row v-if="entity" v-show="!isLoading(loadingName)">
      <b-col :cols="familyCols()" class="entity-col" :class="member !== null ? 'show-all' : ''">
        <entity-detail
          :show-all="member === null"
          :initial-edit-mode="editEntity"
          :is-family="isFamily"
          :entity="entity"
          :adhesions="entityAdhesions"
          @show-entity="onShowEntity"
          @saved="onEntityChanged"
        >
        </entity-detail>
        <div v-if="activeTab && activeTab.selector === 'detail' && entity.id">
          <family-detail
            :family="family"
            :show-all="member === null"
            :read-only="member !== null && isFamily"
            @changed="onEntityChanged"
          ></family-detail>
          <fields-detail
            :show-entity="true"
            :read-only="member !== null"
            :role="member ? member.role : null"
            :individual="member ? member.individual : null"
            :entity="entity"
            :is-family="!!family"
          >
          </fields-detail>
          <entity-accounts
            v-if="isFamily && hasPerm('portal.view_account')"
            :entity="entity"
            :member="member"
            :read-only="member !== null"
          >
          </entity-accounts>
          <entity-certificates
            v-if="member === null && isFamily && hasPerm('certificates.view_certificate')"
            :entity="entity"
          >
          </entity-certificates>
        </div>
        <div v-if="activeTab && activeTab.selector === 'adhesions'">
          <entity-adhesions
            :entity="entity"
            :show-all="member === null"
            @changed="onAdhesionsChanged"
          >
          </entity-adhesions>
        </div>
        <div v-if="activeTab && activeTab.selector === 'activities'">
          <entity-activities
            title="Inscriptions aux activités"
            :youth-home="0"
            :show-all="member === null"
            :entity="entity"
            :adhesions="adhesions"
            :category="activeTab.category"
          >
          </entity-activities>
        </div>
        <div v-if="activeTab && activeTab.selector === 'youth'">
          <entity-youth
            :family="family"
            :reload="reloadYouthHomeActivities"
            :adhesions="adhesions"
            @reloaded="reloadYouthHomeActivities = false"
          >
          </entity-youth>
          <entity-activities
            :title="'Inscriptions ' +  youthHomeLabel"
            :youth-home="1"
            @saved="onInscriptionSaved()"
            :show-all="member === null"
            :entity="entity"
            :adhesions="validAdhesions"
          >
          </entity-activities>
        </div>
        <div v-if="activeTab && activeTab.selector === 'nursery'">
          <entity-nursery
            :entity="entity"
            :member="member"
          ></entity-nursery>
        </div>
        <div v-if="activeTab && activeTab.selector === 'invoices'">
          <entity-invoices
            :show-all="member === null"
            :is-family="isFamily"
            :entity="entity"
            :member="member"
          ></entity-invoices>
        </div>
        <div v-if="activeTab && activeTab.selector === 'agenda'">
          <entity-agenda
            :entity="entity"
          ></entity-agenda>
        </div>
      </b-col>

      <b-col :cols="memberCols()" v-if="member !== null">
        <individual-identity
          :is-family="isFamily"
          :entity="entity"
          :member="member"
          @change="onIndividualChanged($event)"
          @deleted="onIndividualDeleted($event)"
        >
        </individual-identity>
        <legal-representatives-detail
          v-if="member.id && member.individual.childId && member.role && member.role.isChild && isFamily"
          :member="member"
          :entity="entity"
          @change="onLegalRepresentativesUpdated()"
        >
        </legal-representatives-detail>
        <child-detail
          v-if="isFamily && member.id && member.individual.childId && member.role && member.role.isChild"
          :member="member"
        >
        </child-detail>
        <fields-detail
          v-if="member && member.id"
          :show-individual="true"
          :role="member ? member.role : null"
          :individual="member ? member.individual : null"
          :entity="entity"
          :is-family="!!family"
        ></fields-detail>
        <vaccines-detail v-if="member.id" :member="member"></vaccines-detail>
        <individual-link-document
          v-if="member.id && member.role.showIndividualLink"
          :member="member"
        ></individual-link-document>
      </b-col>

      <b-col :cols="2" class="member-col" v-if="areMembersVisible">
        <div v-if="entity.id">
          <b-row>
            <b-col><h3>Membres</h3></b-col>
            <b-col class="text-right">
              <a
                v-if="canChange"
                class="btn btn-primary btn-sm btn-add-member"
                href
                @click.prevent="addNewMember"
                :class="selectorDisabled ? 'disabled' : ''"
              >
                <i class="fa fa-plus"></i>
              </a>
            </b-col>
          </b-row>

          <div v-if="!hasEmailContact" class="warning-text">
            Attention! aucun contact privilégié n'est défini.
          </div>
          <div v-for="section in sections" v-bind:key="section.id">
            <div class="section" :class="section.id" v-if="section.memberships.length > 0">
              <b-row>
                <b-col cols="12">
                  <h3>{{ section.label }}</h3>
                </b-col>
              </b-row>
              <div
                v-for="membership in section.memberships"
                :key="membership.id"
                class="row-line"
                :class="{ selected: membership === member, death: !!membership.individual.deathDate }"
              >
                <div>
                  <a
                    class="membership-selector"
                    @click.prevent="showMembership(membership)"
                    href :class="{ disabled: selectorDisabled, }"
                  >
                    <div
                      v-if="section.canSplit && section.memberships.length > 1 && membership === member"
                      class="text-right small-text"
                    >
                      <a class="" href @click.prevent="splitFamily" title="Séparation" v-b-tooltip>
                        <i class="fa fa-arrows-split-up-and-left"></i>
                      </a>
                    </div>
                    {{ membership.individual.firstAndLastName() }}
                  </a>
                </div>
                <div class="small2" v-if="membership.role.isMain && membership.role.hasContact">
                  <b-row>
                    <b-col>
                      <i
                        class="fa fa-paper-plane"
                        v-if="isEmailContact(membership)"
                        v-b-tooltip="'Contact privilégié'"
                      ></i>
                      {{ membership.role.name }}
                    </b-col>
                  </b-row>
                </div>
                <div class="small2" v-if="membership.individual.birthDate">
                  <b-row>
                    <b-col v-if="membership.individual.birthDate">
                      {{ membership.individual.birthDate | dateToString }}
                    </b-col>
                    <b-col class="text-right" v-if="membership.individual.birthDate">
                      {{ membership.individual.getAge() }}
                    </b-col>
                    <b-col v-if="!membership.individual.birthDate">
                      date de naissance non définie
                    </b-col>
                  </b-row>
                </div>
                <div class="small2" v-if="membership.individual.deathDate">
                  décès le {{ membership.individual.deathDate | dateToString }}
                </div>
                <div class="small2" v-if="membership.role.allowComments && membership.comments">
                  {{ membership.comments }}
                </div>
                <div class="small2" v-if="membership.role.showChildrenAllowed">
                  <span v-if="!membership.role.isMain && !membership.childrenAllowed">
                    <i class="fa fa-exclamation-circle"></i> Ne peut pas récupérer les enfants
                  </span>
                  <span v-if="membership.role.isMain && membership.childrenAllowed">
                    <i class="fa fa-check-circle"></i> Autorisé à récupérer les enfants
                  </span>
                </div>
                <div class="small2">
                  <b-row>
                    <b-col>
                      <span v-for="adhesion of  memberAdhesions(membership)" :key="adhesion.id" class="badge badge-light">
                        {{ adhesion.adhesionType.name }} - {{ adhesion.schoolYear.name }}
                      </span>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </div>
          </div>
          <div v-if="legalRepresentatives.length">
            <div class="section">
              <h3>Parents séparés</h3>
              <div
                v-for="parent in separatedParents"
                :key="parent.individual.id"
                class="row-line"
                :class="{ selected: isParentSelected(parent), }"
              >
                <div>
                  {{ parent.individual.firstAndLastName() }}
                </div>
                <div class="small2" v-for="elt of parent.roles" :key="elt.childIndividual.id">
                  <a class="membership-selector" @click.prevent="showParent(elt)"
                     href :class="{ disabled: selectorDisabled, }"
                  >
                    {{ elt.role.name }} {{ elt.childIndividual.firstAndLastName() }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <confirm-modal
      name="split-family"
      title="Séparation de la famille"
      icon="fa fa-arrows-split-up-and-left"
      text="La famille est-elle séparée?"
      :text2="splitText2"
      @confirmed="onConfirmSplit()"
    >
    </confirm-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapMutations, mapActions } from 'vuex'
import router from '@/router'
import store from '@/store'
import LoadingGif from '@/components/Controls/LoadingGif'
import TabsBar from '@/components/Controls/TabsBar'
import EntityAccounts from '@/components/Entity/EntityAccounts'
import EntityActivities from '@/components/Entity/EntityActivities'
import EntityAdhesions from '@/components/Entity/EntityAdhesions'
import EntityAgenda from '@/components/Entity/EntityAgenda.vue'
import EntityCertificates from '@/components/Entity/EntityCertificates'
import EntityDetail from '@/components/Entity/EntityDetail'
import EntityInvoices from '@/components/Entity/EntityInvoices'
import EntityNursery from '@/components/Entity/EntityNursery'
import EntityYouth from '@/components/Entity/EntityYouth'
import FamilyDetail from '@/components/Family/FamilyDetail'
import FieldsDetail from '@/components/Fields/FieldsDetail'
import ChildDetail from '@/components/Individual/ChildDetail'
import IndividualIdentity from '@/components/Individual/IndividualIdentity'
import IndividualLinkDocument from '@/components/Individual/IndividualLinkDocument'
import LegalRepresentativesDetail from '@/components/Individual/LegalRepresentativesDetail'
import VaccinesDetail from '@/components/Individual/VaccinesDetail'
import ConfirmModal from '@/components/Modals/ConfirmModal'
import PageHeader from '@/components/Layout/PageHeader'
import { BackendMixin } from '@/mixins/backend'
import { makeAdhesion } from '@/types/adhesions'
import { makeFamily, makeLegalRepresentativeEx } from '@/types/families'
import { makeFieldsGroup } from '@/types/fields'
import { makeEntityRole, makeGender, makeEntity, makeEntityMembership, makeMinimalEntity } from '@/types/people'
import { makeSchool } from '@/types/schools'
import { TabItem } from '@/types/tabs'
import { makeVaccine } from '@/types/vaccines'
import { BackendApi, openDocument } from '@/utils/http'
import { compareDates } from '@/utils/sorting'

export default {
  name: 'entity',
  props: {
    entityId: [String, Number],
    isFamily: Boolean,
  },
  mixins: [BackendMixin],
  components: {
    EntityCertificates,
    EntityAccounts,
    EntityAdhesions,
    EntityActivities,
    EntityAgenda,
    EntityInvoices,
    EntityYouth,
    EntityNursery,
    TabsBar,
    IndividualIdentity,
    IndividualLinkDocument,
    ChildDetail,
    EntityDetail,
    FieldsDetail,
    FamilyDetail,
    LoadingGif,
    LegalRepresentativesDetail,
    PageHeader,
    VaccinesDetail,
    ConfirmModal,
  },
  data() {
    return {
      editEntity: false,
      cities: [],
      loadingName: 'entity',
      activeTab: null,
      reloadYouthHomeActivities: false,
      legalRepresentatives: [],
      entity: null,
      family: null,
      member: null,
      areMembersVisible: true,
      adhesions: [],
    }
  },
  async created() {
    this.setEditMode('')
    this.setTab()
    await this.loadData()
    await this.loadAdhesions()
    this.setIndividual()
  },
  watch: {
    family: function(newValue, oldValue) {},
    entity: function(newValue, oldValue) {},
    $route: async function(newValue, oldValue) {
      this.setTab()
      const newEntityId = '' + newValue.params.entityId
      const oldEntityId = '' + oldValue.params.entityId
      await this.loadData()
      if ((newEntityId !== oldEntityId) && (oldEntityId !== 'ajout') && (newEntityId !== 'ajout')) {
        await this.addWarning('Vous voyez à présent la fiche ' + this.entity.name)
      }
      this.setIndividual()
    },
    title() {
      store.dispatch('changeNav', { title: this.title, icon: store.getters.navIcon, })
    },
    member() {
      if (this.member) {
        this.areMembersVisible = true
      }
    },
  },
  computed: {
    title() {
      let title = this.isFamily ? 'Famille' : 'Annuaire'
      if (this.entity) {
        title += ' ' + this.entity.name
      }
      return title
    },
    nursery() {
      return store.getters.config.nursery
    },
    selectorDisabled() {
      return (store.getters.editMode !== '')
    },
    entitySearch() {
      return store.getters.entitySearch
    },
    sections() {
      return [
        {
          id: 'children-section',
          label: 'Enfants',
          memberships: this.getMemberships(true, true),
          canSplit: false,
        },
        {
          id: 'adults-section',
          label: 'Adultes',
          memberships: this.getMemberships(true, false),
          canSplit: true,
        },
        {
          id: 'others-section',
          label: 'Intervenants',
          memberships: this.getMemberships(false, false),
          canSplit: false,
        },
        {
          id: 'members-section',
          label: 'Membres',
          memberships: this.getMemberships(true, false, false),
          canSplit: false,
        }
      ]
    },
    separatedParents() {
      const parents = []
      const individuals = []
      for (const elt of this.legalRepresentatives) {
        let index = individuals.indexOf(elt.individual.id)
        if (index < 0) {
          parents.push(
            {
              individual: elt.individual,
              roles: [elt],
            }
          )
          index = individuals.push(elt.individual.id)
        } else {
          parents[index].roles.push(elt)
        }
      }
      return parents
    },
    editMode() {
      return (store.getters.editMode)
    },
    canView() {
      if (this.isFamily) {
        return this.hasPerm('families.view_familyentity')
      } else {
        return this.hasPerm('people.view_contact')
      }
    },
    canChange() {
      if (this.isFamily) {
        return this.hasPerm('families.change_familyentity')
      } else {
        return this.hasPerm('people.change_contact')
      }
    },
    youthHomeLabel() {
      return store.getters.youthHomeLabel
    },
    youthHomeIcon() {
      return store.getters.youthHomeIcon
    },
    activityMenus() {
      return store.getters.config.activityMenus
    },
    entityTabs() {
      let tabs = [new TabItem('detail', 'Fiche', 'fa fa-th')]
      if (this.hasPerm('adhesions.view_adhesion')) {
        tabs.push(new TabItem('adhesions', 'Adhésions', 'fas fa-id-card'))
      }
      if (this.isFamily && this.hasPerm('activities.view_coreactivityinscription')) {
        if (this.activityMenus.length === 0) {
          tabs.push(new TabItem('activities', 'Activités', 'fas fa-chess-knight'))
        } else {
          for (const menu of this.activityMenus) {
            tabs.push(
              new TabItem(menu.getName(), menu.label, menu.icon, '', 'activities', menu.category)
            )
          }
        }
      }
      if (this.isFamily && this.hasPerm('youth.view_youthhome')) {
        tabs.push(new TabItem('youth', this.youthHomeLabel, this.youthHomeIcon))
      }
      if (this.isFamily && this.nursery && this.hasPerm('nursery.view_')) {
        tabs.push(new TabItem('nursery', 'Crèche', 'fa fa-baby-carriage'))
      }
      if (!this.isFamily && this.hasPerm('agenda.view_activitydate')) {
        tabs.push(new TabItem('agenda', 'Agenda', 'fa fa-calendar-days'))
      }
      if (this.hasPerm('payments.view_invoice')) {
        tabs.push(new TabItem('invoices', 'Facturation', 'fas fa-file-invoice'))
      }
      return tabs
    },
    entityAdhesions() {
      return this.validAdhesions.filter(elt => elt.adhesionType.applyToEntity())
    },
    validAdhesions() {
      return this.adhesions.filter(adhesion => !adhesion.isCancelled)
    },
    splitText2() {
      if (this.member) {
        let text2 = 'Une nouvelle famille sera créée pour '
        text2 += this.member.individual.firstAndLastName()
        text2 += '. Les enfants et intervenants seront associées aux 2 familles.'
        return text2
      }
      return ''
    },
    hasEmailContact() {
      return this.entity.memberships.filter(
        elt => elt.role.isMain && elt.role.hasContact && elt.emailContact
      ).length > 0
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess', 'addWarning']),
    ...mapMutations([
      'startLoading', 'endLoading', 'setGenders', 'setEditMode',
      'setEntityRoles', 'setFieldGroups', 'setVaccines', 'setSchools'
    ]),
    setTab() {
      let tab = this.$route.query.tab
      let tabIndex = tab ? this.entityTabs.map(elt => elt.name).indexOf(tab) : -1
      if (tabIndex >= 0) {
        this.activeTab = this.entityTabs[tabIndex]
      } else {
        this.activeTab = this.entityTabs[0]
      }
    },
    setIndividual() {
      let individualId = +this.$route.query.individual
      if (individualId) {
        let found = false
        for (const member of this.entity.memberships) {
          if (member.individual.id === individualId) {
            this.member = member
            found = true
            break
          }
        }
        if (!found) {
          for (const elt of this.legalRepresentatives) {
            if (elt.individual.id === individualId) {
              this.showParent(elt)
              found = true
              break
            }
          }
        }
      } else {
        const entityId = (this.entityId === 'ajout') ? 0 : +this.entityId
        if (entityId) {
          this.member = null
        }
      }
    },
    onInscriptionSaved() {
      this.reloadYouthHomeActivities = true
    },
    onTabChanged(tab) {
      this.activeTab = tab
      router.push({ path: this.$route.path, query: { tab: tab.name, }, })
    },
    getMemberships(isMain, isChild, familyRole = true) {
      const memberships = this.entity.memberships.filter(
        elt => (
          (elt.role.isMain === isMain) &&
          (elt.role.isChild === isChild) &&
          (elt.role.familyRole === familyRole)
        )
      )
      if (isChild) {
        return memberships.sort(
          (elt1, elt2) => compareDates(elt2.individual.birthDate, elt1.individual.birthDate)
        )
      } else {
        return memberships
      }
    },
    showMembership(membership, noHistory = false) {
      if (!this.selectorDisabled) {
        this.member = membership
        if (!noHistory) {
          const individualId = membership.individual.id
          const tab = this.entityTabs[0]
          this.setTab(tab)
          router.push({ path: this.$route.path, query: { tab: tab.name, individual: individualId, }, })
        }
      }
    },
    onShowEntity() {
      this.member = null
      router.push({ path: this.$route.path, })
    },
    async showParent(legalRepresentative) {
      if (!this.selectorDisabled) {
        this.member = legalRepresentative.toEntityMembership()
      }
    },
    isParentSelected(parent) {
      return (this.member && this.member.individual.id === parent.individual.id)
    },
    addNewMember() {
      if (!this.selectorDisabled) {
        this.activeTab = this.entityTabs[0]
        this.member = makeEntityMembership()
      }
    },
    async loadAdhesions() {
      if (this.hasPerm('adhesions.view_adhesion')) {
        if ((this.entity) && (this.entity.id > 0) && this.isFamily) {
          this.startLoading(this.loadingName)
          let url = '/api/entity/' + this.entity.id + '/adhesions/'
          let backendApi = new BackendApi('get', url)
          try {
            let resp = await backendApi.callApi()
            this.adhesions = resp.data.map(elt => {
              return makeAdhesion(elt)
            })
          } catch (err) {
            await this.addError(this.getErrorText(err))
          }
          this.endLoading(this.loadingName)
        }
      }
    },
    async loadData() {
      if (this.canView && this.entityId) {
        this.startLoading(this.loadingName)
        this.family = null
        this.entity = null
        try {
          let backendApi = null
          let resp = null
          if (this.entityId !== 'ajout') {
            let url = '/api/people/contacts/' + this.entityId + '/'
            if (this.isFamily) {
              url = '/api/families/families/' + this.entityId + '/'
            }
            backendApi = new BackendApi('get', url)
            resp = await backendApi.callApi()
            if (this.isFamily) {
              this.family = makeFamily(resp.data)
              this.entity = this.family.entity
            } else {
              this.family = null
              this.entity = makeEntity(resp.data)
            }
            this.editEntity = false
          } else {
            if (this.isFamily) {
              this.family = makeFamily()
              this.entity = this.family.entity
            } else {
              this.family = null
              this.entity = makeEntity()
            }
            this.editEntity = true
          }

          if (store.getters.genders.length === 0) {
            backendApi = new BackendApi('get', '/api/people/genders/')
            resp = await backendApi.callApi()
            this.setGenders(resp.data.map(elt => makeGender(elt)))
          }

          if (store.getters.roles.length === 0) {
            backendApi = new BackendApi('get', '/api/people/roles/')
            resp = await backendApi.callApi()
            this.setEntityRoles(resp.data.map(elt => makeEntityRole(elt)))
          }

          if (store.getters.fieldsGroups.length === 0) {
            if (this.hasPerm('fields.view_fieldsgroup')) {
              backendApi = new BackendApi('get', '/api/fields/groups/')
              resp = await backendApi.callApi()
              this.setFieldGroups(resp.data.map(elt => makeFieldsGroup(elt)))
            }
          }

          if (store.getters.vaccines.length === 0) {
            if (this.hasPerm('vaccines.view_vaccine')) {
              backendApi = new BackendApi('get', '/api/vaccines/vaccines/')
              resp = await backendApi.callApi()
              this.setVaccines(resp.data.map(elt => makeVaccine(elt)))
            }
          }

          if (store.getters.schools.length === 0) {
            if (this.hasPerm('schools.view_school')) {
              backendApi = new BackendApi('get', '/api/schools/schools/')
              resp = await backendApi.callApi()
              this.setSchools(resp.data.map(elt => makeSchool(elt)))
            }
          }

          if (this.isFamily && this.entityId) {
            await this.loadLegalRepresentatives()
          }
        } catch (err) {
          if (this.isFamily && err.response.status === 404) {
            // la famille n'est pas trouvée: test le contact
            try {
              let contactUrl = '/api/people/contacts/' + this.entityId + '/'
              const backendApi2 = new BackendApi('get', contactUrl)
              const resp = await backendApi2.callApi()
              // redirige vers la fiche contact
              await router.push(
                {
                  name: 'entities-detail',
                  params: { entityId: this.entityId, },
                  query: this.$route.query,
                }
              )
            } catch (err2) {
              await this.addError(this.getErrorText(err))
            }
          } else {
            await this.addError(this.getErrorText(err))
          }
        }
        this.endLoading(this.loadingName)
      }
    },
    familyCols() {
      return (this.member === null) ? (this.areMembersVisible ? 10 : 12) : 3
    },
    memberCols() {
      return (this.member === null) ? 0 : 7
    },
    async onGenerateDocument() {
      if (this.isFamily) {
        const docUrl = '/documents/entity-link-document/<key>/pdf/'
        const docSlug = '' + this.entity.id
        try {
          await openDocument(docUrl, docSlug)
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    async loadLegalRepresentatives() {
      if (this.isFamily && this.family.entity && this.family.entity.id) {
        let url = '/api/families/family-legal-representatives/' + this.family.entity.id + '/'
        let backendApi = new BackendApi('get', url)
        try {
          const resp = await backendApi.callApi()
          this.legalRepresentatives = resp.data.map(elt => makeLegalRepresentativeEx(elt))
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    async onIndividualChanged(event) {
      if (event.member) {
        this.entity = event.entity
        this.member = event.member
      } else {
        await this.loadLegalRepresentatives()
        for (const legal of this.legalRepresentatives) {
          if (legal.individual.id === event.individual.id) {
            this.member = legal.toEntityMembership()
          }
        }
      }
    },
    onIndividualDeleted() {
      this.loadData()
      this.member = null
    },
    onLegalRepresentativesUpdated(event) {
      this.loadLegalRepresentatives()
    },
    async onEntityChanged(event) {
      if (event.family) {
        this.family = event.family
        this.entity = event.family.entity
      } else {
        this.family = null
        this.entity = event.entity
      }
      if (this.entityId === 'ajout') {
        // change url
        this.setEditMode('')
        if (event.family) {
          await router.push({ name: 'families-detail', params: { entityId: this.entity.id, }, })
        } else {
          await router.push({ name: 'entity-detail', params: { entityId: this.entity.id, }, })
        }
      }
    },
    getLinks() {
      let links = []
      const entityId = this.entityId === 'ajout' ? 0 : +this.entityId
      if (this.isFamily && entityId && this.hasPerm('documents.view_entitylinkdocumentsettings')) {
        links.push(
          {
            id: 1,
            label: 'Pdf',
            callback: this.onGenerateDocument,
            icon: 'fa fa-file-pdf',
            cssClass: 'btn-secondary',
          }
        )
      }
      return links
    },
    toggleMembers() {
      if (this.member === null) {
        this.areMembersVisible = !this.areMembersVisible
      }
    },
    memberAdhesions(membership) {
      return this.validAdhesions.filter(
        elt => (
          elt.adhesionType.applyToIndividual() &&
          (elt.individual && elt.individual.id === membership.individual.id)
        )
      )
    },
    onAdhesionsChanged(event) {
      this.adhesions = event.adhesions
    },
    splitFamily() {
      this.$bvModal.show('bv-confirm-modal:split-family')
    },
    async onConfirmSplit() {
      if (this.isFamily && this.family.entity && this.family.entity.id) {
        let url = '/api/split-family/' + this.family.entity.id + '/'
        const data = { membership: this.member.id, }
        let backendApi = new BackendApi('post', url)
        try {
          const resp = await backendApi.callApi(data)
          const newEntity = makeMinimalEntity(resp.data)
          await this.addSuccess('La nouvelle famille a été créée.')
          await router.push({ name: 'families-detail', params: { entityId: newEntity.id, }, })
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    isEmailContact(membership) {
      return membership.role.isMain && membership.role.hasContact && membership.emailContact
    },
  },
}
</script>

<style lang="less">
  .entity-col {
    height: 100%;
  }
  .entity-col.show-all {
    background: #fff;
    border-radius: 8px;
  }
  .entity-col.show-all .header-bar {
    margin-top: 5px;
  }
  .member-col {
    h3 {
      display: inline-block;
      padding-top: 5px;
    }
    .title {
      text-align: right;
    }
    .section {
      padding-bottom: 10px;
      margin-bottom: 10px;
      border-bottom: solid 1px #444;
    }
    .section:last-of-type {
      border-bottom: none
    }
  }
  .row-line.death {
    background: #f9bcbc;
  }
  .row-line.selected {
    background: #888;
    color: #fff;
  }
  .row-line.selected a{
    color: #fff !important;
  }
</style>
